import { Button } from "antd";
import React, { useEffect, useState } from "react";
import i18n from "locales/i18n";
import * as cts from "../../constants/ui/svg";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { changeFollow, getFollow } from "slices/followSlice";
import { errorToast } from "helpers";
import { useSearchParams } from "react-router-dom";
type Props = {
  idStreamer?: any;
};

const ButtonFollowComponent = ({ idStreamer }: Props) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const listFollow = useAppSelector((state) => state.follow.listFollow);
  const listFollowInShop = useAppSelector((state) => state.follow.listFollowInShop);
  const [searchparams] = useSearchParams();
  const key = searchparams.get("key");
  return (
    <>
      <Button
        onClick={() => {
          if (userInfo?._id && idStreamer) {
            dispatch(changeFollow({ streamer: idStreamer, follower: userInfo?._id }));
          } else {
            errorToast("Please login!");
          }
        }}
        className={`${
          !listFollow?.find((i: any) => i?.streamer?._id.includes(idStreamer))?.streamer?._id
            ? key
              ? "bg-[#EF6820] text-white hover:!border-[#EF6820] hover:!text-white"
              : "bg-white text-[#EF6820] border-[#EF6820] hover:!text-[#EF6820] border hover:!border-[#EF6820]"
            : "bg-[#EAECF0] text-[#475467] hover:!text-[#475467] border-none"
        } px-[12px] hover:text-none text-[14px] notosans_medium rounded-[8px] !flex !items-center max-500px:w-[120px] max-500px:justify-center `}
      >
        {!listFollow?.find((i: any) => i?.streamer?._id.includes(idStreamer))?.streamer?._id ? (
          <span className=" !flex !items-center">
            {key ? (
              <span className="mr-[5px]">{cts.heartIcon}</span>
            ) : (
              <span className="mr-[5px]">{cts.heartIconColor}</span>
            )}
            {i18n.t("FOLLOW")}
          </span>
        ) : (
          <>{i18n.t("FOLLOWED")}</>
        )}
      </Button>
    </>
  );
};
export default ButtonFollowComponent;
