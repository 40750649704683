import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ShopState, ShopParams } from "../types/shop";

const initialState = {
  listShop: [],
  detailShop: {} as ShopState,
};

const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    getListShop() {},
    getListShopSuccess(state: any, action: any) {
      state.listShop = action.payload;
    },
    getDetailShop(state: any, action: PayloadAction<string>) {},

    getDetailShopSuccess(state: any, action: PayloadAction<ShopState>) {
      state.detailShop = action.payload;
    },
  },
});

// Actions
export const { getListShop, getListShopSuccess, getDetailShop, getDetailShopSuccess } =
  shopSlice.actions;

// Reducer
export default shopSlice.reducer;
