import request from "./axiosService";

const URL_GALLERY_STREAMER = "/gallery-streamer";

export function getGalleryStreamerAPI(params: { user: any }) {
  return request({
    url: URL_GALLERY_STREAMER,
    method: "get",
    params,
  });
}
