import { FollowParams } from "types/follow";
import request from "./axiosService";
const URL_FOLLOW = "/follow";

export function getFollowAPI(params: FollowParams) {
  return request({
    url: URL_FOLLOW,
    method: "get",
    params,
  });
}
export function changeFollowAPI(data: FollowParams) {
  return request({
    url: URL_FOLLOW,
    method: "post",
    data,
  });
}
