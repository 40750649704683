import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

const initialState = {
  listGallery: [],
};

const gallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    getGallery(state: any, action: PayloadAction<{ user: any }>) {},
    getGallerySuccess(state: any, action: any) {
      state.listGallery = action.payload;
    },
  },
});

// Actions
export const { getGallery, getGallerySuccess } = gallerySlice.actions;
export const gallerySelector = (state: RootState) => state;
// Reducer
export default gallerySlice.reducer;
