import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserOfShopState, UserOfShopParams, ReportParams, ReportState } from "../types/userOfShop";
export interface UserOfShopStateTypes {
  // listHost: Array<UserOfShopState>;
  // detailHost: UserOfShopState;
  reportChannel: ReportState;
}
const initialState: UserOfShopStateTypes = {
  // listHost: [],
  // detailHost: {},
  reportChannel: {} as ReportState,
};

const userOfShopSlice = createSlice({
  name: "userOfShop",
  initialState,
  reducers: {
    // getUserOfShop(state: any, action: PayloadAction<UserOfShopParams>) {},

    // getUserOfShopSuccess(state: any, action: PayloadAction<Array<UserOfShopState>>) {
    //   state.listHost = action.payload;
    // },
    // getDetailHost(state: any, action: PayloadAction<string>) {},

    // getDetailHostSuccess(state: any, action: PayloadAction<UserOfShopState>) {
    //   state.detailHost = action.payload;
    // },
    getReport(state: any, action: PayloadAction<ReportParams>) {},
    getReportSuccess(state: any, action: PayloadAction<ReportState>) {
      state.reportChannel = action.payload;
    },
  },
});

// Actions
export const {
  // getUserOfShop,
  // getUserOfShopSuccess,
  // getDetailHost,
  // getDetailHostSuccess,
  getReport,
  getReportSuccess,
} = userOfShopSlice.actions;

// Reducer
export default userOfShopSlice.reducer;
