import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
const PrivacyPolicyPage = () => {
  const navigate = useNavigate();

  const arr = [
    {
      title: "第1条（基本方針）",
      content: {
        contain:
          "[HOST LIVE]（以下、「本アプリ」といいます。）を運営する[運営者名]（以下、「当社」といいます。）は、ユーザーの個人情報の重要性を認識し、その保護のために以下のプライバシーポリシー（以下、「本ポリシー」といいます。）を定め、適切な取り扱いと保護に努めます。",
      },
    },
    {
      title: "第2条（収集する情報の範囲）",
      content: {
        contain:
          "当社は、ユーザーが本アプリを利用するにあたり、以下の情報を収集することがあります。",
        point: [
          "アカウント情報：ユーザー名、メールアドレス、電話番号、パスワードなどのアカウント作成に必要な情報。",
          "プロフィール情報：ユーザーが任意で提供するプロフィール写真、自己紹介、居住地などの情報。",
          "配信コンテンツ情報：ユーザーが配信する動画、音声、テキスト、画像などのコンテンツに関する情報。",
          "利用履歴：本アプリの利用状況、視聴履歴、いいねやコメントなどのユーザー行動に関する情報。",
          "端末情報：端末の種類、OS、デバイスID、IPアドレス、ブラウザ情報、Cookie、位置情報などの技術的な情報。",
          "その他：当社が提供するサービスにおいてユーザーから提供されるその他の情報。",
        ],
      },
    },
    {
      title: "第3条（情報の利用目的）",
      content: {
        contain: "当社は、収集した情報を以下の目的で利用します。",
        point: [
          "ユーザーアカウントの作成および管理",
          "本アプリの提供、運営、改善",
          "配信コンテンツの提供およびユーザー間のコミュニケーションの促進",
          "ユーザーの興味や関心に基づくコンテンツや広告の提供",
          "本アプリの利用に関する統計データの作成および分析",
          "サポート対応およびユーザーからの問い合わせへの対応",
          "法令または利用規約に違反する行為の防止および対応",
          "その他、事前にユーザーの同意を得た目的",
        ],
      },
    },
    {
      title: "第4条（情報の第三者提供）",
      content: {
        contain: "当社は、次の場合を除き、ユーザーの個人情報を第三者に提供しません。",
        point: [
          "ユーザーの同意がある場合",
          "法令に基づく場合",
          "人の生命、身体または財産の保護のために必要であり、ユーザーの同意を得ることが困難な場合",
          "公衆衛生の向上または児童の健全な育成の推進のために特に必要であり、ユーザーの同意を得ることが困難な場合",
          "国の機関または地方公共団体等が法令の定める事務を遂行することに協力する必要があり、ユーザーの同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合",
          "事業の承継に伴って個人情報が提供される場合（合併、会社分割、営業譲渡等）",
        ],
      },
    },
    {
      title: "第5条（情報の保存期間）",
      content: {
        contain:
          "当社は、収集した個人情報を、その利用目的に必要な期間に限り保存します。当該期間が経過した場合、当社は適切な方法で情報を削除または匿名化します。",
      },
    },
    {
      title: "第6条（ユーザーの権利）",
      content: {
        contain: "ユーザーは、当社が保有する自己の個人情報について、以下の権利を有します。",
        point: [
          "個人情報の閲覧、修正、削除を求める権利",
          "利用停止を求める権利",
          "情報提供先に対する同意を撤回する権利",
        ],
        contain1: "これらの権利を行使する場合、当社のサポートチームにお問い合わせください。",
      },
    },
    {
      title: "第7条（情報の安全管理）",
      content: {
        contain:
          "当社は、ユーザーの個人情報を保護するために、適切な技術的および組織的措置を講じます。これには、不正アクセス、紛失、破壊、改ざん、漏洩の防止が含まれます。",
      },
    },
    {
      title: "第8条（プライバシーポリシーの変更）",
      content: {
        contain:
          "当社は、本ポリシーを随時見直し、変更することがあります。変更後のプライバシーポリシーは、本アプリに掲載した時点で効力を生じます。重大な変更が行われる場合、ユーザーに通知いたします。",
      },
    },
    {
      title: "第9条（お問い合わせ）",
      content: {
        contain: "本ポリシーに関するお問い合わせは、以下の連絡先までご連絡ください。",
        point: ["[運営者名]", "[住所]", "[電話番号]", "[メールアドレス]"],
      },
    },
  ];
  return (
    <div className="flex pt-[10px] justify-center pb-[40px] mt-[2px] pickup_video">
      <div className="size_content_hd w-full flex-col flex">
        <div className="">
          <BreadcrumbComponent breadMain="プライバシーポリシー" />
        </div>
        <div className="w-full flex flex-col items-center">
          <span className="text-[#101828] mt-[10px] text-[24px] notosans_bold max-500px:text-lg max-500px:font-bold">
            プライバシーポリシー
          </span>
          <div className="mt-[20px] w-full rounded-[8px] border border-[#EF6820] p-[40px]">
            {arr.map((x, y) => {
              return (
                <div key={y} className={`${y !== 8 && "mb-[24px]"} flex flex-col`}>
                  <div className="flex items-center">
                    <span>{cts.sendIcon}</span>
                    <span className="ml-[8px] text-[16px] notosans_bold">{x.title}</span>
                  </div>
                  <div className="flex flex-col mt-[8px]">
                    <span className="text-[14px] text-[#1D2939] notosans_normal">
                      {x.content.contain}
                    </span>
                    <div className="flex flex-col">
                      {x.content.point?.map((it) => {
                        {
                          return (
                            <span className="list-item list-inside text-[14px] text-[#1D2939] notosans_normal list-disc">
                              {it}
                            </span>
                          );
                        }
                      })}
                    </div>
                    <span className="text-[14px] text-[#1D2939] notosans_normal">
                      {x.content.contain1}
                    </span>
                  </div>
                </div>
              );
            })}
            <div className="flex w-full items-center justify-center">
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate({
                    pathname: "/",
                  });
                }}
                className="bg-white border border-[#EAECF0] rounded-[8px] flex items-center justify-center hover:!shadow-none hover:!border-[#EAECF0]"
              >
                <span>{cts.iconBack}</span>
                <span className="text-[#EF6820] text-[14px] notosans_medium ml-[8px]"> 戻る</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicyPage;
