import request from "./axiosService";

const URL_CHANNEL = "/channel";

export function getChannelsAPI(params: any) {
  return request({
    url: URL_CHANNEL,
    method: "get",
    params
  });
}