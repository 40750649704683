import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Input, Tooltip } from "antd";
import { io } from "socket.io-client";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import noAva from "../../assets/images/noAva.png";
import vid1 from "../../assets/images/vid2.png";
import vid2 from "../../assets/images/vid1.png";
import ava from "../../assets/images/HOST IMAGE/pic03 (9).jpg";
import ava1 from "../../assets/images/HOST IMAGE/pic01 (3).jpg";
import ava2 from "../../assets/images/HOST IMAGE/pic01 (4).jpg";
import ava3 from "../../assets/images/HOST IMAGE/pic01 (5).jpg";
import ava4 from "../../assets/images/HOST IMAGE/pic01 (6).jpg";
import ava5 from "../../assets/images/HOST IMAGE/pic01 (7).jpg";
import ava6 from "../../assets/images/HOST IMAGE/pic01 (8).jpg";
import ava7 from "../../assets/images/HOST IMAGE/pic01 (9).jpg";
import ava8 from "../../assets/images/HOST IMAGE/pic01.jpg";

import gift from "../../assets/images/redrose-01.png";
import gift1 from "../../assets/images/Balloon-01.png";
import gift2 from "../../assets/images/Crown-01.png";
import gift3 from "../../assets/images/Firework-01.png";
import gift4 from "../../assets/images/WineBottle-011.png";
import gift5 from "../../assets/images/Wine Glass-01.png";
import gift6 from "../../assets/images/Hat-01.png";
import gift7 from "../../assets/images/cake-011.png";

import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import { API_URL_BASE, SOCKET_SERVER, URL_UPLOAD_IMAGE } from "../../constants";

// Components
import { JWPlayer } from "components/Common/JWPlayer";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { fetchChats, createChatSuccess } from "slices/chatSlice";
import { Link } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";
const LiveScreenPage = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  let params = useParams();
  const key = searchparams.get("key");
  const dispatch = useAppDispatch();

  const socket: any = io(`${SOCKET_SERVER}/realtime`);
  const chats = useAppSelector((state) => state.chats.chats);
  const userInfo = useAppSelector((state) => state.users.userInfo);

  const [follow, setFollow] = useState(false);
  const [joined, setJoined] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [valueChat, setValueChat] = useState<any>({});
  const [selectGift, setSelectGift] = useState({ coin: 0, gift: "", push: false });
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [arrNew, setArrNew] = useState<any[]>([
    { id: 1, ava: ava1, name: "アサヒ", chat: "この文章はダミーです。" },
    { id: 2, ava: ava2, name: "Jeans", chat: "この文章はダミーです。行間等を確認するため。" },
    { id: 3, ava: ava3, name: "櫻井絢都 ", chat: "😍😍😍" },
    {
      id: 4,
      ava: ava4,
      name: "とっとこ翔尊  ",
      chat: "文字の大きさ、量、字間、行間等を確認するため。",
    },
    {
      id: 5,
      ava: ava5,
      coin: <span className="mr-[8px]">{cts.kcoint}</span>,
      name: "Jeans  ",
      chat: " 👍",
    },
    { id: 6, ava: ava6, name: "未来 ", chat: "😆😆😆" },
    { id: 7, ava: ava7, name: "Mao ", chat: " この文章はダミーです。" },
  ]);

  const arrDeliveries = [
    {
      id: 1,
      img: vid1,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 2,
      img: vid2,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 1,
      img: vid1,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 2,
      img: vid2,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 1,
      img: vid1,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
    {
      id: 2,
      img: vid2,
      time: "1:30:00",
      title: "動画のタイトル動画のタイトル動画のタイトル動画のタイトル動画のタイトル",
      view: 2023,
      day: "2023/01/01",
    },
  ];
  const arrGift = [
    { gift: gift, coin: 10 },
    { gift: gift1, coin: 10 },
    { gift: gift2, coin: 10 },
    { gift: gift3, coin: 10 },
    { gift: gift4, coin: 10 },
    { gift: gift5, coin: 10 },
    { gift: gift6, coin: 10 },
    { gift: gift7, coin: 10 },
  ];
  const checkLeaveRoomOld = useCallback(async () => {
    const roomOld = localStorage.getItem("room_old");
    if (roomOld !== key) {
      await socket.emit("leaveRoom", roomOld);
    }

    socket.emit("joinRoom", key);

    socket.on("joinedRoom", (data: any) => {
      localStorage.setItem("room_old", data?.room);
    });

    socket.on("chatToClient", (msg: any) => {
      dispatch(createChatSuccess(msg));
    });
  }, []);

  useEffect(() => {
    if (key && !joined) {
      setJoined(true);
      checkLeaveRoomOld();
    }

    return () => {
      // BAD: this will remove all listeners for the 'join' event, which may
      // include the ones registered in another component
      socket.off("joinedRoom");
      socket.off("chatToClient");
      socket.off("joinRoom");
    };
  }, [checkLeaveRoomOld, key]);

  useEffect(() => {
    if (key) {
      dispatch(fetchChats({ receiver: key }));
    }
  }, []);
  const sendChatMessage = () => {
    if (valueChat?.chat && userInfo?._id) {
      const data = {
        sender: userInfo,
        receiver: key,
        room: key,
        message: valueChat.chat,
      };

      if (joined) {
        socket.emit("chatToServer", data);
        // dispatch(createChatSuccess(data));
      }
    }
  };
  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <>
      <div className="pt-[10px] pl-[40px] max-500px:hidden">
        <BreadcrumbComponent breadMain={i18n.t("LIVESTREAM")} />
      </div>
      <div className="pt-[16px] px-[40px] flex mt-[2px] pb-[40px] max-500px:hidden">
        <div className="w-[75%] mr-[10px] flex flex-col">
          <div className="flex justify-between bg-white p-[16px] rounded-t-[8px]">
            <div className="flex items-center">
              <div className="relative flex items-end justify-center mr-[20px]">
                <span className="absolute bottom-[-5px]">{cts.liveMini}</span>
                <div className="h-[50px] w-[50px] rounded-[50%]">
                  <img className="rounded-[50%] object-cover" src={ava} />
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center">
                  <span className="text-[16px] w-[70%] text-[#101828] notosans_bold text_1_line">
                    動画のタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。この文章はダミーです。
                  </span>
                  <div className="bg-white rounded-[4px] flex items-center border border-[#D0D5DD] justify-center h-[24px] w-[64px]">
                    <span>{cts.viewBlackIcon}</span>
                    <span className="text-[14px] text-[#344054] notosans_normal ml-[5px]">
                      2023
                    </span>
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="text-[#475467] text-[14px] notosans_bold">HIKARI</span>
                  <div className="h-[16px] w-px bg-[#98A2B3] mx-[12px]"></div>
                  <div className="flex items-center">
                    {cts.sub1Icon}
                    <span className="flex ml-[5px] rounded-r-[4px] items-center justify-center text-[12px] notosans_medium bg-white h-[30px] text-[#101828]">
                      <span className="mr-[5px] notosans_bold text-[#475467] text-[14px]">
                        2.84M
                      </span>
                      {i18n.t("FOLLOW")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              <Button className="border border-[#D0D5DD] h-[40px] w-[40px] rounded-[8px] flex items-center justify-center mr-[8px]">
                <span>{cts.shareLinkIcon}</span>
              </Button>
              <Button className="border border-[#D0D5DD] h-[40px] w-[40px] rounded-[8px] flex items-center justify-center mr-[8px]">
                <span>{cts.warningBlack}</span>
              </Button>
              <Button
                onClick={() => setFollow((prev) => !prev)}
                className={`${
                  follow
                    ? "bg-[#EAECF0] text-[#475467] border-[rgba(0,0,0,0,5)]"
                    : "bg-[#EF6820] text-white border-none"
                } flex cursor-pointer rounded-[8px] items-center justify-center text-[14px] notosans_medium h-[40px] px-[16px]`}
              >
                {!follow ? (
                  <span className="!flex items-center">
                    <span className="mr-[5px]">{cts.heartIcon}</span>
                    {i18n.t("FOLLOW")}
                  </span>
                ) : (
                  <>{i18n.t("FOLLOWED")}</>
                )}
              </Button>
            </div>
          </div>
          {/* <img className="w-full h-[70vh] rounded-[12px]" src={vid} /> */}
          <JWPlayer
            thumbnail={ava8}
            url={`https://live.freetemplates.xyz:1935/live/general/${key}/playlist.m3u8`}
          />

          <div className="mt-[20px] bg-[#FFFFFF] rounded-[8px] py-[20px] px-[20px] flex flex-col">
            <span className="text-[#101828] text-[20px] notosans_bold ">HIKARIについて</span>
            <span className="text-[#101828] text-[16px] notosans_normal mt-[12px]">
              はじめまして〜！
              <br />
              ドラゴンタイプの新人Vliver、はりゅう あめの と申します ʚ❤︎ɞ
              <br />
              覗いていただけて嬉しいです ありがとう！
              <br />
              <br />
              <br />
              - ̗̀ 🌧 PROFILE 🌧 ̖́-
              <br />
              <br />
              <br />
              名前 : 羽竜あめの(はりゅうあめの) ꒰ঌ🌧໒꒱
              <br />
              Ameno Haryu
              <br />
              <br />
              <br />
              すき : わたがし(雲みたいだから)、パピコ、お肉
              <br />
              すき : お空の観察
              <br />
              すき : 読書、マンガ、アニメ、ゲーム、お絵描き
              <br />
              <br />
              <br />
              とても まったりのんびり配信をしております
              <br />
              乗れそうな雲をみつけたらあめのに教えてね‎️️️⛅️
              <br />
              <br />
              <br />
              ‎🤍⟡.·* next goal… フォロワー様 200人⤴︎
            </span>
          </div>
        </div>
        <div className="w-[30%] ml-[10px] flex flex-col">
          <div className="flex flex-col h-[70vh] rounded-[12px] justify-between">
            <div>
              <div className="bg-[#FAFAFA] border-b rounded-t-[12px] border-[#D0D5DD] py-[12px] px-[16px] flex items-center justify-between">
                <span className="text-[#26272B] text-[16px] notosans_bold">{i18n.t("CMT")}</span>
                <span className="text-[#667085] text-[14px] notosans_normal">
                  {i18n.t("HIDDEN")}
                </span>
              </div>
              <div className="py-[8px] px-[8px] flex items-center border-b border-[#D0D5DD] bg-white">
                <div className="flex flex-1 items-center pr-[20px]">
                  <span className="icon_top1">{cts.top1}</span>
                  <img
                    className="h-[40px] w-[40px] rounded-[50%] mx-[8px] object-cover"
                    src={ava}
                  />
                  <div className="flex flex-col">
                    <span className="text-[#182230] text-[16px] text_1_line notosans_bold max-1590px:text-[14px]">
                      とっとこ翔尊
                    </span>
                    <span className="flex items-center text-[#475467] mt-[8px] max-1590px:text-[14px] text-[16px] notosans_normal">
                      120 {cts.coinIcon1}
                    </span>
                  </div>
                </div>
                <div className="bg-[#D0D5DD] w-px h-[18px]"></div>
                <div className="flex flex-1 items-center flex-col pl-[20px]">
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <span>{cts.top2}</span>
                      <img
                        className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                        src={ava1}
                      />
                      <span className="text-[#182230] text_1_line text-[16px] max-1590px:text-[14px] notosans_bold">
                        アサヒ
                      </span>
                    </div>
                    <span className="flex items-center text-[#475467] text-[16px] max-1590px:text-[14px] notosans_normal">
                      60 {cts.coinIcon1}
                    </span>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <span>{cts.top3}</span>
                      <img
                        className="h-[20px] w-[20px] rounded-[50%] mx-[8px] object-cover"
                        src={ava2}
                      />
                      <span className="text-[#182230] text_1_line text-[16px] notosans_bold max-1590px:text-[14px]">
                        櫻井絢都
                      </span>
                    </div>
                    <span className="flex items-center max-1590px:text-[14px] text-[#475467] text-[16px] notosans_normal">
                      10 {cts.coinIcon1}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${
                !userInfo?._id && "rounded-b-[12px]"
              } flex justify-start h-full relative flex-col box_chat overflow-y-auto pt-[8px] bg-white`}
            >
              {chats?.map((x: any, y: any) => {
                return (
                  <div key={y} className="flex pb-[12px] px-[16px] items-center">
                    <img
                      className="h-[24px] w-[24px] rounded-[50%] mr-[16px] object-cover"
                      src={x?.ava}
                    />
                    <div>
                      {x?.coin}
                      <span
                        // onClick={() => setFollow((prev) => !prev)}
                        className={`${
                          x.coin ? "text-[#EF6820]" : "text-[#797983]"
                        } text-[14px] notosans_bold mr-[8px]`}
                      >
                        {x?.sender?.nickName}
                      </span>
                      <span className="text-[14px] notosans_normal">{x?.message}</span>
                    </div>
                  </div>
                );
              })}
              {selectGift.coin !== 0 && selectGift.gift !== "" && selectGift.push === true && (
                <div className="bg-[#FEF6EE] mx-[16px] mb-[12px] rounded-[10px] px-[16px] py-[8px] flex justify-between items-center">
                  <div className="flex items-center">
                    <img
                      className="h-[40px] w-[40px] rounded-[50%] mr-[12px] object-cover"
                      src={
                        userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : noAva
                      }
                    />
                    <div className="flex flex-col">
                      <span className="text-[#101828] text-[16px] notosans_bold">
                        {userInfo?.nickName}
                      </span>
                      <span className="flex items-center text-[#667085] text-[16px] notosans_normal">
                        {selectGift.coin}
                        {cts.coinIcon1}
                      </span>
                    </div>
                  </div>
                  <img
                    className="h-[60px] w-[60px] rounded-[50%] object-cover"
                    src={selectGift.gift}
                  />
                </div>
              )}
            </div>
            e
            {openBox && (
              <>
                <div
                  style={{ boxShadow: " 0px 2px 2px rgba(0,0,0,0.1) inset" }}
                  className="flex items-center justify-between bg-white pt-[12px] px-[12px]"
                >
                  <div className="flex items-center">
                    <span className="text-[#101828] text-[20px] notosans_bold mr-[12px]">
                      {i18n.t("SUPPORT")}
                    </span>
                    <img
                      className="h-[20px] w-[20px] rounded-[50%] mr-[8px] object-cover"
                      src={userInfo?.avatar ? userInfo?.avatar : noAva}
                    />
                    <span className="text-[#101828] text-[20px] notosans_bold">
                      {userInfo?.nickName}
                    </span>
                  </div>
                  <Button onClick={() => setOpenBox(false)} className="border-none shadow-none p-0">
                    {cts.close1Icon}
                  </Button>
                </div>
                <div className="flex items-center bg-white pb-[12px] px-[12px]">
                  <span className="text-[#101828] mr-[8px] text-[14px] notosans_normal">
                    {i18n.t("CURRENT_POINT")}
                  </span>
                  <span className="text-[#101828] text-[16px] notosans_bold mr-[2px]">10000</span>
                  <span>{cts.coinIcon1}</span>
                  <Link
                    className="bg-[#EF6820] px-[16px] flex items-center h-[32px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[13px]"
                    to="/purchase-point"
                  >
                    {i18n.t("PURCHASE_POINT")}
                  </Link>
                </div>
                <div
                  style={{ boxShadow: "0px 2px rgba(0,0,0,0.1)" }}
                  className="h-full bg-white overflow-y-auto rank_scroll px-[12px] pb-[12px]"
                >
                  <div className="overflow-y-auto mt-[12px] grid grid-cols-4 gap-4">
                    {arrGift.map((x, y) => {
                      return (
                        <div
                          onClick={() => setSelectGift({ push: false, coin: x.coin, gift: x.gift })}
                          key={y}
                          className={`${
                            selectGift.gift === x.gift && "bg-[#FEF6EE]"
                          } hover:bg-[#FEF6EE] cursor-pointer py-[8px] rounded-[8px] flex flex-col items-center justify-center`}
                        >
                          <img src={x.gift} className="w-[48px] h-[48px]" />
                          <span className=" flex items-center">
                            {x.coin} {cts.coinIcon1}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {emojiPicker && (
              <div className="h-[60%] emoji_picker">
                <EmojiPicker
                  // searchDisabled="true"
                  previewConfig={{ showPreview: false }}
                  // emojiStyle="google"
                  onEmojiClick={(e) =>
                    setValueChat({
                      id: Math.random(),
                      ava: ava8,
                      name: userInfo?.nickName,
                      chat: valueChat.chat ? valueChat.chat + e.emoji : e.emoji,
                    })
                  }
                  height="100%"
                  width="100%"
                />
              </div>
            )}
            {userInfo?._id && (
              <div className="rounded-b-[12px] flex items-center border-[#D0D5DD] border-t">
                <Input
                  value={valueChat.chat}
                  onChange={(e) =>
                    setValueChat({
                      id: Math.random(),
                      ava: ava8,
                      name: userInfo.nickName,
                      chat: e.target.value,
                    })
                  }
                  onPressEnter={() => {
                    if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                      sendChatMessage();
                      setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                    }
                  }}
                  className="h-full border-none rounded-bl-[12px] inp_chat"
                  placeholder="メッセージを送信"
                  suffix={
                    <div className="flex items-center">
                      {!emojiPicker ? (
                        <Button
                          onClick={() => setEmojiPicker((prev) => !prev)}
                          className="border-none shadow-none h-[20px] w-[20px] p-0 mr-[15px]"
                        >
                          {cts.happyIcon}
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => {
                              setEmojiPicker((prev) => !prev);
                              if (openBox) {
                                setOpenBox(false);
                              }
                            }}
                            className="border-none shadow-none h-[20px] w-[20px] p-0 mr-[15px]"
                          >
                            {cts.happyIcon}
                          </Button>
                        </>
                      )}

                      <Button
                        onClick={() => {
                          setOpenBox((prev) => !prev);
                          if (emojiPicker) {
                            setEmojiPicker(false);
                          }
                        }}
                        className="border-none shadow-none p-0"
                      >
                        {cts.giftBox}
                      </Button>
                    </div>
                  }
                />
                <div
                  onClick={() => {
                    setSelectGift({ ...selectGift, push: true });
                    setOpenBox(false);
                    setEmojiPicker(false);
                    if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                      sendChatMessage();
                      setArrNew([...arrNew, valueChat]);
                      setValueChat({});
                    }
                  }}
                  className="h-[46px] cursor-pointer w-[56px] flex items-center justify-center rounded-br-[12px] text-white text-[14px] notosans_medium bg-[#EF6820]"
                >
                  {cts.sendMess}
                </div>
              </div>
            )}
          </div>
          <div className="pt-[24px] flex flex-col">
            <span className="text-[20px] notosans_bold mb-[12px]">
              {i18n.t("RECENT_DELIVERIES")}
            </span>
            <div className="overflow-y-auto h-[550px]">
              {arrDeliveries?.map((x, y) => {
                return (
                  // <div key={y} className="pb-[20px] flex flex-col">
                  //   <div className="relative h-[220px]  flex items-end justify-end">
                  //     <div className="bg-[#101828] text-white px-[6px] py-[2px] rounded-[4px] absolute mr-[8px] mb-[8px]">
                  //       {x.time}
                  //     </div>
                  //     <img className="rounded-[8px] w-full object-cover" src={x.img} />
                  //   </div>
                  //   <span className="mt-[8px] text-[#101828] text-[14px] notosans_bold text_1_line">
                  //     {x.title}
                  //   </span>
                  //   <div className="flex items-center">
                  //     <span className="mr-[5px] sz15_view"> {cts.view1Icon}</span>
                  //     <span className="text-[#667085] text-[12px] notosans_normal">{x.view}</span>
                  //     <span className="mx-[8px]">{cts.dotIcon}</span>
                  //     <span className="text-[#667085] text-[12px] notosans_normal">{x.day}</span>
                  //   </div>
                  // </div>
                  <div key={y} className="mb-[12px] flex">
                    <div className="relative mr-[12px] w-full h-[125px] justify-end flex">
                      <div className="bg-[rgba(0,0,0,0.4)] mt-[5px] mr-[5px] absolute rounded-[4px] flex items-center justify-center px-[4px] py-[2px]">
                        <span className="icon_sz_12">{cts.view1Icon}</span>
                        <span className="text-[#FFFFFF] text-[12px] ml-[4px]">{x.view}</span>
                      </div>
                      <img className="rounded-[8px] w-full h-full object-cover" src={x.img} />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[#101828] text-[14px] notosans_bold text_2_line">
                        {" "}
                        {x.title}
                      </span>
                      <div className="mt-[8px] flex items-center">
                        <img
                          className="rounded-[50%] w-[28px] h-[28px] object-cover mr-[8px]"
                          src={x.img}
                        />
                        <span className="text-[#101828] text-[12px] notosans_normal">
                          ニックネーム
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {width <= 500 && (
        <div className="relative overflow-hidden h-screen">
          <div className="absolute h-full flex flex-col justify-between">
            <div className="h-[90px] bg-gradient-to-b pt-[46px] px-[16px] from-[#202020]/70 via-[#202020]/40 to-[#202020]/0 w-[100vw]">
              <div className="flex items-center">
                <div className="bg-[#1018284D] flex-1 mr-[12px] px-[6px] rounded-[100px] h-[44px] flex justify-between items-center">
                  <div className="flex items-center">
                    <img src={vid1} className="h-[36px] w-[36px] rounded-[50%] mr-[4px]" />
                    <div className="flex flex-col">
                      <span className="text-[14px] notosans_medium text-white">
                        鈴木龍二🥁Martin🥁
                      </span>
                      <div className="flex items-center">
                        <span className="icon_respon_sub_color">{cts.subIcon}</span>
                        <span className="text-[13px] notosans_normal ml-[4px] text-white">
                          2.84M{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Button className="border-none shadow-none hover:!bg-white hover:!text-[#EF6820] p-0 bg-white text-[#EF6820] h-[32px] flex items-center justify-center w-[94px] rounded-[99px]">
                    <span>{cts.heartIconColor}</span>
                    <span className="text-[13px] notosans_medium text-[#EF6820] ml-[4px]">
                      {i18n.t("FOLLOW")}
                    </span>
                  </Button>
                </div>
                <Button className="p-0 border-none shadow-none flex items-center justify-center rounded-[50%] bg-[#1018284D] h-[44px] w-[44px] hover:bg-[#1018284D]">
                  <span className="icon_respon_share_color">{cts.shareLinkIcon}</span>
                </Button>
              </div>
            </div>
            <div
              className={`${
                openBox ? "" : "px-[16px] pb-[12px]"
              } h-[45%] flex flex-col justify-end bg-gradient-to-t from-[#202020]/80 via-[#202020]/40 to-[#202020]/0 w-[100vw]`}
            >
              <div
                className={`${
                  (openBox && "hidden") || (emojiPicker && "hidden")
                } flex justify-end h-full relative flex-col box_chat overflow-y-auto pb-[8px] `}
              >
                <div className="overflow-auto box_chat_mobile">
                  {chats?.map((x: any, y: any) => {
                    return (
                      <div key={y} className="flex pb-[12px]">
                        <img
                          className="h-[32px] w-[32px] rounded-[50%] mr-[8px] object-cover"
                          src={vid1}
                        />
                        <div className="flex flex-col">
                          {x?.coin}
                          <span
                            // onClick={() => setFollow((prev) => !prev)}
                            className={`${
                              x.coin ? "text-[#EF6820]" : "text-white"
                            } text-[13px] notosans_bold `}
                          >
                            {x?.sender?.nickName}
                          </span>
                          <span className="text-[13px] notosans_normal text-white">
                            {x?.message}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                  {selectGift.coin !== 0 && selectGift.gift !== "" && selectGift.push === true && (
                    <div className="bg-[#101828]/30 rounded-[10px] px-[16px] py-[8px] flex justify-between items-center">
                      <div className="flex items-center">
                        <img
                          className="h-[32px] w-[32px] rounded-[50%] mr-[8px] object-cover"
                          src={userInfo?.avatar ? userInfo?.avatar : noAva}
                        />
                        <div className="flex flex-col">
                          <span className="text-white text-[13px] notosans_bold">
                            {userInfo?.nickName}
                          </span>
                          <span className="flex items-center text-white text-[13px] notosans_normal">
                            {selectGift.coin}
                            <span className="ml-[5px]">{cts.coinIcon1}</span>
                          </span>
                        </div>
                      </div>
                      <img
                        className="h-[32px] w-[32px] rounded-[50%] object-cover"
                        src={selectGift.gift}
                      />
                    </div>
                  )}
                </div>
              </div>
              {openBox && (
                <>
                  <div className="flex items-center justify-between bg-white pt-[12px] px-[12px]">
                    <div className="flex items-center">
                      <span className="text-[#101828] text-[14px] notosans_bold mr-[12px]">
                        {i18n.t("SUPPORT")}
                      </span>
                      <img
                        className="h-[20px] w-[20px] rounded-[50%] mr-[8px] object-cover"
                        src={userInfo?.avatar ? userInfo?.avatar : noAva}
                      />
                      <span className="text-[#101828] text-[14px] notosans_bold">
                        鈴木龍二🥁Martin🥁
                      </span>
                    </div>
                    <Button
                      onClick={() => setOpenBox(false)}
                      className="border-none shadow-none p-0"
                    >
                      {cts.close1Icon}
                    </Button>
                  </div>
                  <div className="flex items-center bg-white pb-[12px] px-[12px]">
                    <span className="text-[#101828] mr-[8px] text-[14px] notosans_normal">
                      {i18n.t("CURRENT_POINT")}
                    </span>
                    <span className="text-[#101828] text-[14px] notosans_bold mr-[2px]">10000</span>
                    <span>{cts.coinIcon1}</span>
                    <Link
                      className="bg-[#EF6820] px-[16px] flex items-center h-[26px] rounded-[8px] cursor-pointer text-white ml-[16px] notosans_medium text-[12px]"
                      to="/purchase-point"
                    >
                      {i18n.t("PURCHASE_POINT")}
                    </Link>
                  </div>
                  <div className="h-full bg-white overflow-y-auto rank_scroll px-[12px] pb-[12px]">
                    <div className="overflow-y-auto mt-[12px] grid grid-cols-4 gap-4">
                      {arrGift.map((x, y) => {
                        return (
                          <div
                            onClick={() =>
                              setSelectGift({ push: true, coin: x.coin, gift: x.gift })
                            }
                            key={y}
                            className={`${
                              selectGift.gift === x.gift && "bg-[#FEF6EE]"
                            } hover:bg-[#FEF6EE] h-[90px] cursor-pointer py-[8px] rounded-[8px] flex flex-col items-center justify-center`}
                          >
                            <img src={x.gift} className="w-[48px] h-[48px]" />
                            <span className=" flex items-center">
                              {x.coin} {cts.coinIcon1}
                            </span>
                            <Button
                              onClick={() => {
                                setSelectGift({ ...selectGift, push: true });
                                setOpenBox(false);
                              }}
                              className={`${
                                selectGift.gift === x.gift ? "block" : "hidden"
                              }  p-0 border-none rounded-t-none shadow-none text-[12px] w-full bg-[#EF6820] notosans_medium text-white h-[20px] flex items-center justify-center`}
                            >
                              {i18n.t("GIVE")}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {userInfo?._id && (
                <div className={`${openBox && "hidden"} flex items-center justify-between`}>
                  <Input
                    value={valueChat.chat}
                    onChange={(e) =>
                      setValueChat({
                        id: Math.random(),
                        ava: ava8,
                        name: userInfo.nickName,
                        chat: e.target.value,
                      })
                    }
                    onPressEnter={() => {
                      if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                        sendChatMessage();
                        setArrNew([...arrNew, valueChat]);
                        setValueChat({});
                      }
                    }}
                    className="h-[40px] bg-[#F5F5F540] border-none inp_live_respon_cmt w-[75%] rounded-[20px]"
                    placeholder="メッセージを送信"
                  />
                  <Button
                    onClick={() => {
                      setOpenBox((prev) => !prev);
                      if (emojiPicker) {
                        setEmojiPicker(false);
                      }
                    }}
                    className="border-none h-[40px] w-[40px] rounded-[50%] bg-[#1018284D] flex items-center justify-center shadow-none p-0"
                  >
                    {cts.giftBox}
                  </Button>
                  <Button
                    onClick={() => {
                      // setSelectGift({ ...selectGift, push: true });
                      // setOpenBox(false);
                      setEmojiPicker(false);
                      if (Object.keys(valueChat).length !== 0 && valueChat?.chat !== "") {
                        sendChatMessage();
                        setArrNew([...arrNew, valueChat]);
                        setValueChat({});
                      }
                    }}
                    className="h-[40px] cursor-pointer p-0 w-[40px] flex items-center justify-center rounded-[50%] border-none text-white text-[14px] notosans_medium bg-[#EF6820]"
                  >
                    {cts.sendMess}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {/* <JWPlayer
            thumbnail={ava8}
            url={`https://live.freetemplates.xyz:1935/live/general/${key}/playlist.m3u8`}
          /> */}
          <img src={vid2} className="w-full h-screen object-cover" />
        </div>
      )}
    </>
  );
};
export default LiveScreenPage;
