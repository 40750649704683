import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  chats: <any>[],
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    fetchChats(state, action) {},
    fetchChatsSuccess(state, action) {
      state.chats = action.payload;
    },

    createChatSuccess(state, action) {
      state.chats = [...state.chats, action.payload];
    },
  },
});

// Action
export const { fetchChats, fetchChatsSuccess, createChatSuccess } = chatsSlice.actions;

// Reducer
const historyPlanReducer = chatsSlice.reducer;

export default historyPlanReducer;
