import { getToken } from "helpers/Token";
import i18n from "locales/i18n";
import { FaCamera } from "react-icons/fa";

// Import Swiper styles
import { Input, Select } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ConfirmModal from "components/Common/ConfirmModal";
import { addComponent, handleImageError, warningToast } from "helpers";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUserAvatar, updateUser } from "slices/userSlice";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { URL_UPLOAD_IMAGE } from "../../../constants";
const { TextArea } = Input;

const TabProfile = () => {
  const [stateA, setStateA] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [fileUpload, setFileUpload] = useState<any>();
  const [fileDataURL, setFileDataURL] = useState();
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const [profile, setProfile] = useState<any>(userInfo);
  const inputRef = useRef<any>();
  const imageMimeType = /image\/(png|jpg|jpeg|webp)/i;
  const token = getToken();
  const changeHandler = (e: any) => {
    const file = e.target.files[0];
    if (!file?.type?.match(imageMimeType)) {
      alert("Image mime type is not valid");
      return;
    }
    const imageUrl = URL.createObjectURL(file);
    if (imageUrl && document.getElementById("avatar") !== null) {
      const avatarElement = document.getElementById("avatar") as HTMLImageElement;
      if (avatarElement) {
        avatarElement.src = imageUrl;
      }
    }
    setFileUpload(file);
  };


  useEffect(() => {
    if (userInfo._id) {
      setProfile(userInfo);
    }
  }, [userInfo, userInfo._id]);

  useEffect(() => {
    let fileReader = new FileReader(),
      isCancel = false;
    if (fileUpload) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileUpload);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileUpload]);

  const onSave = async () => {
    dispatch(
      updateUser({
        token: token as String,
        id: userInfo._id,
        formData: {
          nickName: profile?.nickName || userInfo?.nickName,
          file: fileUpload || userInfo?.avatar,
          gender: profile?.gender || userInfo?.gender,
          description: profile.description || userInfo?.description,
        },
      })
    );
  };

  const onDeleteAvatar = () => {
    if(userInfo && userInfo.avatar){
      addComponent(ConfirmModal, {
        title: i18n.t("IMAGE_DELETION"),
        question: i18n.t("ARE_YOU_SURE_DELETE_IT"),
        textBtn1: i18n.t("CANCEL"),
        textBtn2: i18n.t("DELETE"),
        onNext: () => {
          dispatch(
            deleteUserAvatar({
              token: token as String,
              id: userInfo?._id as String,
              data: {
                deleteAvatar: 1
              }
            })
          );
        },
      })
    }else{
      warningToast(i18n.t("IMAGE_EMPTY"));
    }
  }

  return (
    <div className="flex flex-col justify-start items-center gap-[24px] bg-[#F2F4F7] rounded-[8px]">
      <div className=" bg-white rounded-[8px] p-[20px] flex flex-col max-500px:p-0 max-500px:m-4">
        <span className="text-[20px] notosans_bold text-[#101828] max-500px:hidden">
          {i18n.t("PROFILE")}
        </span>
        <span className="text-[#475467] text-[14px] notosans_normal mt-[8px] max-500px:hidden">
          {i18n.t("UPDATE_UR_INFO")}
        </span>
        <div className="mt-[20px] border-b border-t border-[#EAECF0] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:border-t-0 max-500px:pb-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
          <span className="text-[#344054] max-800px:col-span-2 text-[16px] notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1">
            {i18n.t("USER_NAME")}
          </span>
          <Input className="h-[44px] col-span-2 rounded-[8px]" value={userInfo?.userName} disabled />
        </div>
        <div className="mt-[20px] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:py-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
          <span className="text-[#344054] max-800px:col-span-2 text-[16px] notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1">
            {i18n.t("NICK_NAME")}
          </span>
          <Input
            className="h-[44px] col-span-2 rounded-[8px]"
            value={profile?.nickName}
            onChange={(e) => {
              setProfile({ ...profile, nickName: e.target.value });
            }}
          />
        </div>
        <div className="mt-[20px] border-t border-[#EAECF0] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:py-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
          <span className="text-[#344054] max-800px:col-span-2 text-[16px] notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1">
            {i18n.t("SEX")}
          </span>
          <Select
            value={profile?.gender}
            className="h-[44px] col-span-2 rounded-[8px] slc_sex max-500px:w-full"
            onChange={(value) => setProfile({ ...profile, gender: value })}
            options={[
              { value: "different", label: i18n.t("UNSELECTED") },
              { value: "male", label: i18n.t("MALE") },
              { value: "female", label: i18n.t("WOMAN") },
            ]}
          />
        </div>
        <div className="mt-[20px] border-b border-t border-[#EAECF0] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:py-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
          <span className="text-[#344054] max-800px:col-span-2 text-[16px] notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1">
            {i18n.t("INTRODUCTION")}
          </span>
          <div className="col-span-2 flex flex-col">
            <TextArea
              value={profile?.description}
              onChange={(e) => setProfile({ ...profile, description: e.target.value })}
              className="col-span-2 rounded-[8px]"
              rows={5}
            />
            <span className="text-[#98A2B3] text-[12px] notosans_normal max-500px:mt-1">
              {i18n.t("INF_ENTER")}
            </span>
          </div>
        </div>
        <div className="mt-[20px] border-b border-t border-[#EAECF0] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:py-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:justify-start max-500px:items-center max-500px:gap-2">
          <span className="text-[#344054] text-[16px] max-800px:col-span-2 notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1 max-500px:flex-[2]">
            {i18n.t("PROFILE_PHOTO")}
          </span>
          <div className="max-500px:flex max-500px:flex-[3] min-w-[300px] flex justify-start items-center gap-[30px]">
            <div className="w-[80px] h-[80px] mt-[10px] flex justify-center items-center relative cursor-pointer" onClick={() => {
              document.getElementById("inputAvatar")?.click();
            }}>
              <label htmlFor="inputAvatar" className="absolute z-50 -right-1 bottom-0 cursor-pointer flex items-center justify-center  bg-transparent" >
                <button className="flex items-center justify-center cursor-pointer bg-primary01 h-6 w-6 rounded-lg">
                  <FaCamera className="cursor-pointer" size={12} color={"white"} />
                </button>
                <Input
                  id="inputAvatar"
                  ref={inputRef}
                  accept=".png, .jpg, .jpeg .webp"
                  type="file"
                  onChange={changeHandler}
                  className="hidden opacity-0 "
                />
              </label>
              <img
                id="avatar"
                alt=""
                className="h-[80px] w-[80px] rounded-[50%] img_ava object-cover"
                src={fileUpload ? fileDataURL : `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}`}
                onError={handleImageError}
              />
            </div>
            <button
              onClick={() => { onDeleteAvatar() }}
              className="w-[130px] h-[42px] bg-white rounded-[8px] flex justify-center items-center gap-[5px] px-[16px] py-[12px] border-[#F04438] border-[1px] border-solid">
              <span>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.0466 3.48659C12.9733 3.37992 11.8999 3.29992 10.8199 3.23992V3.23325L10.6733 2.36659C10.5733 1.75325 10.4266 0.833252 8.86661 0.833252H7.11994C5.56661 0.833252 5.41994 1.71325 5.31328 2.35992L5.17328 3.21325C4.55328 3.25325 3.93328 3.29325 3.31328 3.35325L1.95328 3.48659C1.67328 3.51325 1.47328 3.75992 1.49994 4.03325C1.52661 4.30659 1.76661 4.50659 2.04661 4.47992L3.40661 4.34659C6.89994 3.99992 10.4199 4.13325 13.9533 4.48659C13.9733 4.48659 13.9866 4.48659 14.0066 4.48659C14.2599 4.48659 14.4799 4.29325 14.5066 4.03325C14.5266 3.75992 14.3266 3.51325 14.0466 3.48659Z" fill="#F04438" />
                  <path d="M12.8199 5.42675C12.6599 5.26008 12.4399 5.16675 12.2132 5.16675H3.78658C3.55991 5.16675 3.33325 5.26008 3.17991 5.42675C3.02658 5.59341 2.93991 5.82008 2.95325 6.05341L3.36658 12.8934C3.43991 13.9067 3.53325 15.1734 5.85991 15.1734H10.1399C12.4666 15.1734 12.5599 13.9134 12.6332 12.8934L13.0466 6.06008C13.0599 5.82008 12.9732 5.59341 12.8199 5.42675ZM9.10658 11.8334H6.88658C6.61325 11.8334 6.38658 11.6067 6.38658 11.3334C6.38658 11.0601 6.61325 10.8334 6.88658 10.8334H9.10658C9.37991 10.8334 9.60658 11.0601 9.60658 11.3334C9.60658 11.6067 9.37991 11.8334 9.10658 11.8334ZM9.66658 9.16675H6.33325C6.05991 9.16675 5.83325 8.94008 5.83325 8.66675C5.83325 8.39341 6.05991 8.16675 6.33325 8.16675H9.66658C9.93991 8.16675 10.1666 8.39341 10.1666 8.66675C10.1666 8.94008 9.93991 9.16675 9.66658 9.16675Z" fill="#F04438" />
                </svg>
              </span>
              <span className="text-[#F04438] notosans_medium text-[14px] leading-[18px]">{i18n.t("DELETE_AVATAR")}</span>
            </button>
          </div>
        </div>
        {/* <div className="relative">
          <div className="absolute flex items-end flex-col w-full justify-end h-[222px]">
            <div className="absolute z-50 right-0 bottom-0 w-[40px] cursor-pointer flex items-center justify-center h-[40px] rounded-[50%] bg-[#EAECF0]">
              <span className="flex change_camera items-center justify-between">
                {cts.cameraIcon}
                <Input
                  ref={inputRef}
                  accept=".png, .jpg, .jpeg .webp"
                  type="file"
                  onChange={changeHandler}
                  className=" absolute h-[40px] w-[40px] text-white bg-black inp_upload opacity-0"
                />
              </span>
            </div>
          </div>
          {fileUpload ? (
            <img
              alt=""
              className="h-[222px] w-[222px] rounded-[50%] mx-[20px] img_ava object-cover"
              src={fileUpload ? fileDataURL : vid}
            />
          ) : (
            <img
              className="h-[222px] w-[222px] rounded-[50%] mx-[20px] img_ava object-cover"
              src={vid1}
            />
          )}
        </div> */}
        <div className="mt-[20px] items-center grid grid-cols-5 gap-4 max-500px:mt-3 max-500px:flex max-500px:w-full">
          <span className="text-[#344054] max-800px:col-span-2 text-[16px] notosans_bold max-500px:hidden "></span>
          <div onClick={() => onSave()} className="col-span-2 max-500px:w-full cursor-pointer">
            <div className=" flex bg-[#EF6820] px-[16px] w-[58px] items-center text-[#FFFFFF] text-[13px] notosans_medium h-[40px] rounded-[8px] cursor-pointer max-500px:w-full max-500px:justify-center max-500px:item-center">
              {i18n.t("SAVE")}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white min-h-[98px] mb-[40px] flex justify-start items-center gap-[20px] rounded-[8px] p-[20px]" >
        <div className="min-w-[280px] min-h-[58px] flex flex-col justify-start items-start gap-[8px]">
          <h3 className="notosans_bold text-[20px] leading-[30px] text-[#101828]">{i18n.t("DELETE_YOUR_ACCOUNT")}</h3>
          <span className="notosans_normal text-[14px] leading-[20px] text-[#475467]">{i18n.t("ALL_ACOUNT_DATA_WILL_BE_DELETED")}</span>
        </div>
        <button className="min-w-[123px] h-[40px] whitespace-nowrap rounded-[8px] px-[16px] py-[8px] bg-[#F04438] text-white text-[13px] leading-[24px] notosans_medium"
          onClick={() => {
            navigate('/my-account/delete-account')
          }}>
          {i18n.t("ACCOUNT_DELECTION")}
        </button>
      </div>
    </div>
  );
};
export default TabProfile;
