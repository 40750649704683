import request from "./axiosService";

const URL_GIFT_NAME = "/gift-name";

export function giftNameListApi(token: string, params: any) {
  return request({
    url: URL_GIFT_NAME,
    method: "get",
    token,
    params: { ...params, token: undefined }
  })
}