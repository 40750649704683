import { PayloadAction } from "@reduxjs/toolkit";
import { historySendGiftAPI } from "apis/paymentHistoryApi";
import { AxiosResponse } from "axios";
import { formatServerDateToDDMMYYYYHHMM } from "helpers";
import { call, put, takeLatest } from "redux-saga/effects";
import * as act from "../slices/paymentHistorySlice";

function* getHistorySendGiftSaga(action: PayloadAction<{date: string | Date, userSend: string, token: string}>){
    const {date, userSend, token} = action.payload
    try {
        const res: AxiosResponse = yield call(historySendGiftAPI, {
            date, userSend, token
        });
        if(res.status === 200 || res.status === 201){
            const arrNew = res.data?.reduce((acc: any, item: any) => {
                const existingItemIndex = acc.findIndex((i: any) => 
                  item?.userReceive?._id === i?.userReceive?._id &&
                  item?.gift?._id === i?.gift?._id &&
                  formatServerDateToDDMMYYYYHHMM(item?.updatedAt) === formatServerDateToDDMMYYYYHHMM(i?.updatedAt)
                );
              
                if (existingItemIndex !== -1) {
                  acc[existingItemIndex] = {
                    ...acc[existingItemIndex],
                    number: acc[existingItemIndex].number + 1
                  };
                } else {
                  acc.push({...item, number: 1});
                }
              
                return acc;
              }, []);
            yield put(act.getHistorySendGiftSuccess(arrNew));
        }
    } catch (error) {
        
    }
}

export default function* paymentHistoryWatcher() {
    yield takeLatest(act.getHistorySendGift.type, getHistorySendGiftSaga);
}