import { LiveStreamParams } from "types/liveStream";
import request from "./axiosService";
const URL_LIVE_STREAM = "/live-streamer/no-login";
export function getLiveStreamAPI(params: LiveStreamParams) {
  return request({
    url: URL_LIVE_STREAM,
    method: "get",
    params,
  });
}
