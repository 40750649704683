import { User } from "../models";
import request from "./axiosService";

const URL_LOGIN = "/auth/login";
const URL_LOGOUT = "/logout";

export function loginAPI(data: any) {
  return request({
    url: URL_LOGIN,
    method: "post",
    data
  });
}

export function logoutAPI(token: string, params: {}) {
  return request({
    url: URL_LOGOUT,
    method: "get",
    params,
    token,
  });
}