import { useCallback, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
// URLs
import { API_URL_BASE, SOCKET_SERVER } from "../constants";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  createChannelSuccess,
  fetchChannels,
  deleteChannelSuccess,
  setOnlineClients,
  leaveRomClient,
} from "slices/channelSlice";
import { Console } from "console";

const socket: any = io(`${SOCKET_SERVER}/realtime`);

export default function SocketPage() {
  const dispatch = useAppDispatch();

  const channelsSt = useAppSelector((state) => state.channels.channels);
  const roomsInfo = useAppSelector((state) => state.channels.roomsInfo);

  const [activeRoom, setActiveRoom] = useState<any>(null);
  const [joined, setJoined] = useState<any>(false);

  const toggleRoomMembership = async () => {
    if (activeRoom) {
      await socket.emit("joinRoom", activeRoom);
    }
  };

  useEffect(() => {
    setActiveRoom("general");
    dispatch(fetchChannels({ channelName: "general" }));
  }, []);

  // useEffect(() => {
  //   if (channelsSt?.length > 0) {
  //     const filterChannel = channelsSt.filter(
  //       (f: any) => !roomsInfo.some((item: any) => item.room === f?.user?._id) && f?.user
  //     );

  //     for (let i of filterChannel) {
  //       if (i?.user?._id) {
  //         socket.emit("joinRoom", i?.user?._id);
  //       }
  //     }
  //   }
  // }, [channelsSt?.length, roomsInfo]);

  useEffect(() => {
    if (socket && activeRoom && !joined) {
      socket.on("connect", (room: string) => {
        setJoined(true);
      });

      toggleRoomMembership();

      socket.on("joinedRoom", (data: string) => {
        dispatch(setOnlineClients(data));
      });

      socket.on("channelToClient", (msg: any) => {
        dispatch(createChannelSuccess(msg));
      });

      socket.on("leftRoom", (data: any) => {
        dispatch(leaveRomClient({ room: data?.room, countClients: data?.countClients }));
        dispatch(deleteChannelSuccess(data));
      });
    }

    return () => {
      socket.off("connect");
      // socket.off('disconnect', onDisconnect);
      socket.off("leftRoom");
      socket.off("joinRoom");
      socket.off("joinedRoom");
      socket.off("channelToClient");
    };
  }, [activeRoom]);

  return <></>;
}
