import { ReportParams, UserOfShopParams } from "types/userOfShop";
import request from "./axiosService";
const URL_USER_OF_SHOP = "/user-of-shop";
const URL_REPORT = "/daily-report/no-login";
export function getDetailHostAPI(_id: string) {
  return request({
    url: URL_USER_OF_SHOP + "/" + _id,
    method: "get",
  });
}
export function getUserOfShopAPI(params: UserOfShopParams) {
  return request({
    url: URL_USER_OF_SHOP,
    method: "get",
    params,
  });
}
export function getReportAPI(params: ReportParams) {
  return request({
    url: URL_REPORT,
    method: "get",
    params,
  });
}
