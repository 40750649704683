import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { EventLiveParams, EventLiveState } from "types/eventLive";
export interface EventLiveStateTypes {
  listEvent: Array<EventLiveState>;
  detailEvent: EventLiveState;
}
const initialState: EventLiveStateTypes = {
  listEvent: [],
  detailEvent: {},
};

const eventLiveSlice = createSlice({
  name: "eventLive",
  initialState,
  reducers: {
    getEventLive(state: any, action: PayloadAction<EventLiveParams>) {},
    getEventLiveSuccess(state: any, action: PayloadAction<Array<EventLiveState>>) {
      state.listEvent = action.payload;
    },
    getDetailEvent(state: any, action: PayloadAction<string>) {},
    getDetailEventSuccess(state: any, action: PayloadAction<EventLiveState>) {
      state.detailEvent = action.payload;
    },
  },
});

// Actions
export const { getEventLive, getEventLiveSuccess, getDetailEvent, getDetailEventSuccess } =
  eventLiveSlice.actions;
export const eventLiveSelector = (state: RootState) => state;
// Reducer
export default eventLiveSlice.reducer;
