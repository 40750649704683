import LoginPage from "features/auth/Login";
import DeliveryPage from "features/delivery";
import HomePage from "features/home";
import ListStore from "features/listStore";
import DetailStore from "features/listStore/component/DetailStore";
import LiveScreenPage from "features/liveScreen";
import LiveStreamPage from "features/liveStream";
import PrivateRouters from "features/master";
import MyPage from "features/myPage";
import DeleteAccountPage from "features/myPage/component/deleteAccount";
import PaymentSuccess from "features/paymentSuccess";
import PickupPage from "features/pickup";
import PrivacyPolicyPage from "features/privacyPolicy";
import PurchasePoint from "features/purchasePoints";
import RankingPage from "features/rank";
import RegionPage from "features/region";
import PaymentSquarePage from "features/square";
import StreamerProfile from "features/streamerProfile";
import SupportContactPage from "features/supportContact";
import TermsOfServicePage from "features/termsOfService";
import { Route, Routes } from "react-router-dom";
import "./assets/scss/index.scss";

function App() {
  return (
    <>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route element={<PrivateRouters />}>
          <Route index element={<HomePage />} />
          <Route path="livescreen" element={<LiveScreenPage />} />
          <Route path="pickup" element={<PickupPage />} />
          <Route path="delivery" element={<DeliveryPage />} />
          <Route path="region" element={<RegionPage />} />
          <Route path="rank" element={<RankingPage />} />
          <Route path="streamer-profile" element={<StreamerProfile />} />
          <Route path="my-account" element={<MyPage />} />
          <Route path="purchase-point" element={<PurchasePoint />} />
          <Route path="payment-success" element={<PaymentSuccess />} />
          <Route path="livestream" element={<LiveStreamPage />} />
          <Route path="list-store" element={<ListStore />} />
          <Route path="detail-store" element={<DetailStore />} />
          <Route path="payment-square" element={<PaymentSquarePage />} />
          <Route path="terms-of-service" element={<TermsOfServicePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="my-account/delete-account" element={<DeleteAccountPage />} />
          <Route path="support-contact" element={<SupportContactPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
