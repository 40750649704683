import * as cts from "../../constants/ui/svg";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const TermsOfServicePage = () => {
  const navigate = useNavigate();

  const arr = [
    {
      title: "第1条（適用範囲）",
      content: [
        "この利用規約（以下、「本規約」といいます。）は、[アプリ名]（以下、「本アプリ」といいます。）の利用に関する条件を定めるものです。本アプリを利用する全てのユーザー（以下、「ユーザー」といいます。）に適用されます。",
      ],
    },
    {
      title: "第2条（利用登録）",
      content: [
        "ユーザーが本アプリを利用するには、事前に利用登録を行う必要があります。",
        "利用登録の申請は、申請者が本規約に同意のうえ、運営者が定める手続きに従って行うものとします。",
        "運営者は、利用登録の申請に対し、運営者の判断に基づき承認または拒否することができ、承認の理由を申請者に開示する義務を負わないものとします。",
      ],
    },
    {
      title: "第3条（アカウントの管理）",
      content: [
        "ユーザーは、自己の責任において、アカウントの管理および利用を行うものとします。",
        "ユーザーは、自己のアカウントを第三者に利用させることはできません。",
        "アカウントの不正利用によって生じた損害について、運営者は一切の責任を負わないものとします。",
      ],
    },
    {
      title: "第4条（禁止事項）",
      content: [
        "ユーザーは、本アプリの利用にあたり、以下の行為を行ってはなりません。",
        "法令または公序良俗に違反する行為",
        "犯罪行為に関連する行為",
        "他のユーザー、第三者、または運営者の権利を侵害する行為",
        "本アプリの運営を妨害する行為",
        "他のユーザーに対する嫌がらせや誹謗中傷",
        "アプリを通じて不適切なコンテンツを配信する行為",
        "運営者が不適切と判断するその他の行為",
      ],
    },
    {
      title: "第5条（コンテンツの権利）",
      content: [
        "ユーザーが本アプリを通じて配信または投稿したコンテンツ（以下、「ユーザーコンテンツ」といいます。）に関する権利は、ユーザーに帰属します。",
        "ユーザーは、ユーザーコンテンツについて、運営者に対し、無償で利用する権利（複製、配布、表示、公表、二次利用など）を付与するものとします。",
        "運営者は、ユーザーコンテンツに関して、法令または本規約に違反する場合、これを削除する権利を有します。",
      ],
    },
    {
      title: "第6条（免責事項）",
      content: [
        "本アプリの提供において、運営者は、アプリの内容や安全性、正確性、信頼性、継続性などについて一切保証しません。",
        "ユーザーが本アプリを利用することによって生じたあらゆる損害について、運営者は一切責任を負わないものとします。",
        "運営者は、本アプリの内容や利用に関して、予告なく変更、中断、終了することがあります。",
      ],
    },
    {
      title: "第7条（本規約の変更）",
      content: [
        "運営者は、必要に応じて本規約を変更することができるものとします。",
        "本規約の変更は、変更後の規約を本アプリ上に掲載した時点で効力を生じるものとします。",
        "ユーザーが規約変更後に本アプリを利用した場合、当該変更に同意したものとみなします。",
      ],
    },
    {
      title: "第8条（準拠法および管轄）",
      content: [
        "本規約の解釈および適用に関しては、日本法を準拠法とし、ユーザーと運営者の間で生じた紛争については、運営者の所在地を管轄する]裁判所を専属的合意管轄とします。",
      ],
    },
    { title: "第9条（附則）", content: ["本規約は、[日付]から施行されます。"] },
  ];
  return (
    <div className="flex pt-[10px] justify-center pb-[40px] mt-[2px] pickup_video">
      <div className="size_content_hd w-full flex-col flex ">
        <div className="">
          <BreadcrumbComponent breadMain="利用規約" />
        </div>
        <div className="w-full flex flex-col items-center">
          <span className="text-[#101828] mt-[10px] text-[24px] notosans_bold max-500px:text-lg max-500px:font-bold">
            利用規約
          </span>
          <div className="mt-[20px] w-full rounded-[8px] border border-[#EF6820] p-[40px]">
            {arr.map((x, y) => {
              return (
                <div key={y} className="mb-[24px] flex flex-col">
                  <div className="flex items-center">
                    <span>{cts.sendIcon}</span>
                    <span className="ml-[8px] text-[16px] notosans_bold">{x.title}</span>
                  </div>
                  <div className="flex flex-col mt-[8px]">
                    {x.content.map((it) => {
                      return (
                        <span className="text-[14px] text-[#1D2939] notosans_normal">{it}</span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <span className="text-[14px] text-[#1D2939] notosans_normal">【2024年8月1日制定】</span>
            <div className="flex w-full items-center justify-center">
              <Button
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate({
                    pathname: "/",
                  });
                }}
                className="bg-white border border-[#EAECF0] rounded-[8px] flex items-center justify-center hover:!shadow-none hover:!border-[#EAECF0]"
              >
                <span>{cts.iconBack}</span>
                <span className="text-[#EF6820] text-[14px] notosans_medium ml-[8px]"> 戻る</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsOfServicePage;
