import request from "./axiosService";
const URL_SHOP = "/shop";
const URL_SHOP_NO_LOGIN = "/shop/list-shop-no-login";

export function getShopAPI() {
  return request({
    url: URL_SHOP_NO_LOGIN,
    method: "get",
  });
}
export function getDetailShopAPI(_id: string) {
  return request({
    url: URL_SHOP + "/" + _id,
    method: "get",
  });
}
