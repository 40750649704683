import { Button } from "antd";
import React from "react";
import i18n from "locales/i18n";
import * as cts from "../../constants/ui/svg";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { changeFollow, changeFollowInShop } from "slices/followSlice";
import { errorToast } from "helpers";
type Props = {
  idStreamer?: any;
};

const ButtonFollowV1Component = ({ idStreamer }: Props) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const listFollowInShop = useAppSelector((state) => state.follow.listFollowInShop);
  return (
    <>
      <Button
        onClick={() => {
          if (userInfo?._id && idStreamer) {
            dispatch(changeFollowInShop({ streamer: idStreamer, follower: userInfo?._id }));
          } else {
            errorToast("Please login!");
          }
        }}
        className={`${
          !listFollowInShop
            ?.filter((item: any) =>
              item.followers?.find((x: any) => x?.follower?._id === userInfo._id)
            )
            .find((x: any) => x?.streamer === idStreamer)
            ? "bg-white text-[#EF6820] border-[#EF6820] hover:!text-[#EF6820] border hover:!border-[#EF6820]"
            : "bg-[#EAECF0] text-[#475467] hover:!text-[#475467] border-none"
        } px-[12px] hover:text-none text-[14px] notosans_medium rounded-[8px] !flex !items-center max-500px:w-[120px] max-500px:justify-center `}
      >
        {!listFollowInShop
          ?.filter((item: any) =>
            item.followers?.find((x: any) => x?.follower?._id === userInfo._id)
          )
          .find((x: any) => x?.streamer === idStreamer) ? (
          <span className=" !flex !items-center">
            <span className="mr-[5px]">{cts.heartIconColor}</span>
            {i18n.t("FOLLOW")}
          </span>
        ) : (
          <>{i18n.t("FOLLOWED")}</>
        )}
      </Button>
    </>
  );
};
export default ButtonFollowV1Component;
