import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type OnlineClientRoom = {
  room: string;
  countClients: number;
};
const initialState = {
  channels: <any>[],
  roomsInfo: <OnlineClientRoom[]>[],
};

const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    fetchChannels(state, action) { },
    fetchChannelsSuccess(state, action) {
      state.channels = action.payload;
    },

    createChannelSuccess(state, action) {
      const index = state.channels.findIndex(
        (i: any) =>
          i.channelName === action.payload.channelName && i?.user?._id === action.payload.user._id
      );
      if (index === -1) {
        state.channels = [...state.channels, action.payload];
      } else {
        state.channels[index] = action.payload;
      }
    },

    deleteChannelSuccess(state, action) {
      const filterChannel = state.channels.filter(
        (i: any) => i?.user?._id !== action?.payload?.user?._id
      );

      state.channels = filterChannel;
    },

    setOnlineClients(state, action) {
      const index = state.roomsInfo.findIndex(
        (i: OnlineClientRoom) => i.room === action.payload.room
      );

      if (index === -1) {
        state.roomsInfo = [...state.roomsInfo, action.payload];
      } else {
        state.roomsInfo[index] = action.payload;
      }
    },

    leaveRomClient(state, action) {
      const index = state.roomsInfo.findIndex(
        (i: OnlineClientRoom) => i.room === action.payload.room
      );

      if (index > -1) {
        state.roomsInfo[index] = action.payload;
      }
    },
  },
});

// Action
export const { fetchChannels, fetchChannelsSuccess, createChannelSuccess, deleteChannelSuccess, setOnlineClients, leaveRomClient } =
  channelsSlice.actions;

// Reducer
const historyPlanReducer = channelsSlice.reducer;

export default historyPlanReducer;
