import type { TableProps } from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import i18n from "locales/i18n";
import moment from "moment";
import * as cts from "../../../constants/ui/svg";

import { DatePicker, DatePickerProps, Table } from "antd";
import { useAppSelector } from "app/hooks";
import { formatMoney, getCurrentDate } from "helpers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { getWebHistoryUserPoint } from "slices/userSlice";
interface DataType {
  key: any;
  time: any;
  productName: any;
  total: any;
  method: any;
}
interface Props {}
const TabPaymentHistory: React.FC<Props> = ({}) => {
  //useState
  const [dataTable, setDataTable] = useState<any>([]);
  //selector
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const webHistoryPointUser = useAppSelector((state) => state.users.webHistoryPointUser);
  //dispatch
  const dispatch = useDispatch();
  //params
  const params = {
    type: "month",
    user: userInfo._id ?? "",
    date: moment().startOf("month").format("YYYY-MM-DD"),
  };
  //Datepicker change
  const onChange: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    const paramsDatePicker = { ...params, date: moment(dateString).format("YYYY-MM-DD") };
    dispatch(getWebHistoryUserPoint({ params: paramsDatePicker }));
  };
  //Columns header
  const columns: TableProps<DataType>["columns"] = [
    {
      title: (
        <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("DATE_N_TIME")}</span>
      ),
      dataIndex: "time",
      key: "time",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_normal max-500px:flex max-500px:w-[150px] max-500px:justify-center max-500px:items-center">
          {text}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("PRODUCT_NAME")}</span>
      ),
      dataIndex: "productName",
      key: "productName",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_bold flex items-center justify-center w-full max-500px:flex max-500px:w-[100px] max-500px:justify-center max-500px:items-center">
          {text}
          {cts.coinMini}
        </span>
      ),
    },
    {
      title: <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("TOTAL")}</span>,
      key: "total",
      dataIndex: "total",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_normal max-500px:flex max-500px:w-[100px] max-500px:justify-center max-500px:items-center">
          {i18n.t("YEN")}
          {formatMoney(text)}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("PAYMENT_METHOD")}</span>
      ),
      key: "method",
      dataIndex: "method",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_normal max-500px:flex max-500px:w-[100px] max-500px:justify-center max-500px:items-center">
          {text}
        </span>
      ),
    },
  ];
  //row
  const dataMemo: DataType[] =
    useMemo(() => {
      return webHistoryPointUser?.map((item: any, index: any) => {
        return {
          key: index,
          time: moment(item.createdAt).format("MM/DD/YYYY - HH:mm"),
          productName: item.points,
          total: item.points,
          method: "Credit Card",
        };
      });
    }, [webHistoryPointUser]) ?? [];
  //useEffect
  useEffect(() => {
    dispatch(getWebHistoryUserPoint({ params: params }));
  }, [dispatch, userInfo._id]);
  useEffect(() => {
    setDataTable(dataMemo);
  }, [dataMemo]);
  return (
    <div className="mb-[40px] bg-white min-h-[440px] rounded-[8px] p-[20px] flex flex-col max-500px:mx-0 max-500px:px-0 max-500px:py-3">
      <span className="text-[20px] notosans_bold text-[#101828] max-500px:hidden">
        {i18n.t("PAYMENT_HIS")}
      </span>
      <div className="flex items-center justify-between pb-[20px] mb-[20px] border-b border-[#EAECF0] max-500px:px-3 max-500px:border-none max-500px:pb-0 max-500px:mb-3">
        <span className="text-[#475467] text-[16px] notosans_normal mt-[8px] flex items-center ">
          {i18n.t("CURRENT_COIN")}
          <span className="text-[16px] notosans_bold mx-[5px]">{userInfo?.points || 0}</span>
          {cts.coinMini}
        </span>
        <DatePicker
          className="h-[44px] w-[116px] rounded-[8px]"
          onChange={onChange}
          picker="month"
          defaultValue={dayjs(getCurrentDate(), "YYYY-MM-DD")}
          suffixIcon={cts.calendarIcon}
          allowClear={false}
          locale={locale}
        />
      </div>
      <Table
        className="table_payment"
        locale={{
          emptyText: (
            <div className="text-textGray900 text-[16px] notosans_normal py-4 font-[600]">
              {i18n.t("NO_TRANSACTION")}
            </div>
          ),
        }}
        columns={columns}
        dataSource={dataTable}
      />
    </div>
  );
};
export default TabPaymentHistory;
