import { call, put, select, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

import i18n from "../locales/i18n";

import { toast, errorToast } from "../helpers";

import { showLoading } from "../slices/commonSlice";
import { getGalleryStreamerAPI } from "../apis";
import * as act from "../slices/galleryStreamerSlice";

function* getGallerySaga(action: PayloadAction<{user:any}>) {
  try {
    const res: AxiosResponse<any> = yield call(getGalleryStreamerAPI, action.payload);
    if (res.data) {
      yield put(act.getGallerySuccess(res.data));
      yield put(showLoading(false));
    } else {
      console.log(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    // errorToast(i18n.t("GET_ERROR"));
    console.log("error", i18n.t("GET_ERROR"));

    yield put(showLoading(""));
  }
}

export default function* galleryWatcher() {
  yield takeEvery(act.getGallery, getGallerySaga);
}
