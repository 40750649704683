import request from "./axiosService";

const URL_HISTORY_USE_GIFT = "/gift-points/web-history-gift-point?type_get=gift&type=month";
// Api POS Demo
export function historySendGiftAPI(data: {
    date: string | Date,
    userSend: string,
    token: string,
}) {
  return request({
    url: `${URL_HISTORY_USE_GIFT}&date=${data.date}&userSend=${data.userSend}`,
    method: "get",
    token: data.token
  });
}

