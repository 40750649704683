import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import counterReducer from "../features/counter/counterSlice";

import channels from "../slices/channelSlice";
import chats from "../slices/chatSlice";
import general from "../slices/commonSlice";
import eventLive from "../slices/eventLiveSlice";
import follow from "../slices/followSlice";
import gallery from "../slices/galleryStreamerSlice";
import giftName from "../slices/giftNameSlice";
import liveStream from "../slices/liveStreamSlice";
import paymentHistory from "../slices/paymentHistorySlice";
import payment from "../slices/paymentSlice";
import shop from "../slices/shopSlice";
import userOfShop from "../slices/userOfShopSlice";
import users from "../slices/userSlice";
import rootSaga from "./rootSaga";

const rootReducer = combineReducers({
  // router: connectRouter(history),
  counter: counterReducer,
  channels,
  chats,
  users,
  general,
  payment,
  shop,
  userOfShop,
  follow,
  eventLive,
  giftName,
  gallery,
  liveStream,
  paymentHistory
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,

  // Add saga to toolkit
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  // getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware(history))
});

// Add root  saga
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
