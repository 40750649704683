import React, { useState, useEffect } from "react";
import i18n from "locales/i18n";
import * as cts from "../../constants/ui/svg";
import { Button, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import { useAppSelector } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "helpers";
import moment from "moment";

type Props = {};

const PaymentSuccess = (props: Props) => {
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const navigate = useNavigate();
  const [total, setTotal] = useState<any>();
  const [buyCoin, setBuyCoin] = useState<any>();
  useEffect(() => {
    const totalPayment = localStorage.getItem("total_payment");
    if (totalPayment) {
      setTotal(totalPayment);
    } else {
      setTotal(0);
    }
  }, []);
  useEffect(() => {
    const buyCoin = localStorage.getItem("buyCoin");

    if (buyCoin) {
      setBuyCoin(buyCoin);
    } else {
      setBuyCoin("");
    }
  }, []);

  return (
    <div className="flex items-center justify-center py-[50px] bg-white  border-[#D0D5DD] border-t max-500px:px-4 ">
      <div className="w-[40%] flex flex-col items-center justify-center max-500px:w-full">
        <span>{cts.checkIconSuccess}</span>
        <span className="text-[18px] notosans_bold mt-[12px] max-500px:hidden">
          {i18n.t("UR_PAYMENT_HAS_BEEN_COMPLETED")}
        </span>
        <div className="hidden max-500px:flex flex-col justify-center items-center  mt-[12px] ">
          <span className="text-[18px] notosans_bold">"お支払いが完了しました。"</span>
          <span className="text-[18px] notosans_bold">ご購入ありがとうございました！</span>
        </div>
        <div className="bg-[#D0D5DD] w-full h-px my-[20px]"></div>
        <div className="flex items-center justify-between w-full">
          <span className="text-[16px] text-[#475467] notosans_normal">{i18n.t("EMAIL")}</span>
          <span className="text-[#101828] text-[16px] notosans_normal">{userInfo?.email}</span>
        </div>
        <div className="flex items-center justify-between w-full mt-[12px]">
          <span className="text-[16px] text-[#475467] notosans_normal">
            {i18n.t("TOTAL_AMOUNT")}
          </span>
          <span className="text-[#101828] text-[16px] notosans_bold">
            {i18n.t("YEN")} {formatMoney(Number(total))}
          </span>
        </div>
        <div className="flex items-center justify-between w-full mt-[12px]">
          <span className="text-[16px] text-[#475467] notosans_normal">{i18n.t("CONTENT")}</span>
          <span className="text-[#101828] text-[16px] notosans_normal">
            {i18n.t("PURCHASE_POINT")}
          </span>
        </div>
        <div className="flex items-center justify-between w-full mt-[12px]">
          <span className="text-[16px] text-[#475467] notosans_normal">
            {i18n.t("TRANSACTION_CODE")}
          </span>
          <span className="text-[#101828] text-[16px] notosans_normal">202401010001</span>
        </div>
        <div className="flex items-center justify-between w-full mt-[12px]">
          <span className="text-[16px] text-[#475467] notosans_normal">
            {i18n.t("PAYMENT_OF_PAYMENT")}
          </span>
          <span className="text-[#101828] text-[16px] notosans_normal">
            {i18n.t("INTERNET_BANK")}
          </span>
        </div>
        <div className="flex items-center justify-between w-full mt-[12px]">
          <span className="text-[16px] text-[#475467] notosans_normal">
            {i18n.t("TRADING_DAY")}
          </span>
          <span className="text-[#101828] text-[16px] notosans_normal">
            {moment().format("YYYY/MM/DD - HH:mm")}
          </span>
        </div>
        <div className="bg-[#D0D5DD] w-full h-px my-[20px]"></div>
        <span className="text-[#667085] text-[16px] notosans_normal">{i18n.t("U_CAN_CHECK")}</span>
        <div className="items-center grid grid-cols-2 gap-4 mt-[12px] w-full">
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
              if (buyCoin === "buyCoin") {
                navigate(-3);
              } else {
                navigate(-2);
              }
            }}
            className="hover:!text-[#667085] hover:!border-[#667085] border-[#667085] text-[#667085] shadow-[0px_1px_2px_0px_#1018280D] h-[48px] rounded-[8px] flex items-center justify-center text-[16px] notosans_bold"
          >
            {i18n.t("CANCEL")}
          </Button>
          <Button
            onClick={() => {
              window.scrollTo(0, 0);
              navigate({
                pathname: "/my-account",
                // search: createSearchParams({
                //   key: `${x.user?._id}`,
                // }).toString(),
              });
            }}
            className="hover:!text-white bg-[#EF6820] text-white shadow-[0px_1px_2px_0px_#1018280D] h-[48px] rounded-[8px] flex items-center justify-center text-[16px] notosans_bold"
          >
            {i18n.t("TO_MY_PAGE")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
