import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import i18n from "locales/i18n";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { warningToast } from "helpers";
import { useDispatch } from "react-redux";
import { supportContact } from "slices/userSlice";
import { useAppSelector } from "app/hooks";
const SupportContactPage = () => {
  //state
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<Boolean>(false);
  //selector
  const showLoading = useAppSelector((state) => state.general.loading);
  //dispatch
  const dispatch = useDispatch();
  //navigate
  const navigate = useNavigate();
  //onFinish
  const onFinish = (values: any) => {
    if (values.agreePrivacy) {
      dispatch(supportContact({ data: values }));
    } else {
      warningToast(i18n.t("AGREE_PRIVACY_POLICY_ALERT"));
    }
  };
  //useEffect
  useEffect(() => {
    if (showLoading === "support_contact") {
      setLoading(true);
    } else if (showLoading === "send_success") {
      setLoading(false);
      form.resetFields();
    } else {
      setLoading(false);
    }
  }, [showLoading]);
  return (
    <div className="support-contact-container ">
      <div className="breadcrumb-support mt-[10px] px-40 lg:px-10">
        <BreadcrumbComponent breadMain="お問い合わせ" />
      </div>
      <div className="support-contact-wrapper">
        <div className="form-header flex flex-col items-center gap-3">
          <div className="title text-textGray900 text-[24px] notosans_bold ">
            {i18n.t("INQUIRY")}
          </div>
          <div className="sub-title text-textGray500 notosans_normal">
            {i18n.t("PLEASE_FILL_OUT_FORM")}
          </div>
        </div>
        <div className="form-body my-10 bg-white border-[1px] p-[40px] rounded-lg border-textGray300">
          <Form onFinish={onFinish} form={form}>
            <div className="input-row">
              <div className="label">
                {i18n.t("FULL_NAME")}
                <span className="text-[#F04438]">*</span>
              </div>
              <Form.Item
                name="name"
                rules={[{ required: true, message: i18n.t("ENTER_FULL_NAME_ALERT") }]}
              >
                <Input className="h-10" />
              </Form.Item>
            </div>
            <div className="input-row">
              <div className="label">
                {i18n.t("EMAIL")}
                <span className="text-[#F04438]">*</span>
              </div>
              <Form.Item
                name="email"
                rules={[{ required: true, message: i18n.t("ENTER_EMAIL_ALERT") }]}
              >
                <Input type="email" className="h-10" />
              </Form.Item>
            </div>
            <div className="input-row">
              <div className="label">{i18n.t("INQUIRY_CONTENT_DETAILS")}</div>
              <Form.Item name="content">
                <TextArea rows={4} />
              </Form.Item>
            </div>

            <div className="input-row privacy-info">
              <div className="top text-textGray800 text-[14px] notosans_normal font-[400]">
                {i18n.t("PLEASE_AGREE_TO_HOST_LIVE_PRIVACY_POLICY")}
              </div>
              <div className="bottom">
                <div className="text-[14px] notosans_normal text-textGray800 mt-[16px] cursor-pointer flex h-[30px] items-center justify-center">
                  <Form.Item
                    name="agreePrivacy"
                    valuePropName="checked"
                    initialValue={false}
                    className="!mb-0"
                  >
                    <input disabled={loading ? true : false} type="checkbox" className="mr-2" />
                  </Form.Item>
                  <span
                    onClick={() => {
                      window.scrollTo(0, 0);
                      navigate("/privacy-policy");
                    }}
                    className="border-b-[1px] border-textGray800"
                  >
                    {i18n.t("PRIVACY_LINK")}
                  </span>
                  {i18n.t("I_AGREE")} <span className="text-[#F04438]">*</span>
                </div>
              </div>
            </div>
            <div className="input-submit-button flex justify-center items-center mt-[20px]">
              <Form.Item>
                <button
                  className={`bg-orangePrimary px-4 py-2 rounded-lg text-white notosans_medium text-[13px] ${
                    loading ? "bg-orange-400" : ""
                  }`}
                  disabled={loading ? true : false}
                >
                  {i18n.t("SEND_V2")}
                </button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SupportContactPage;
