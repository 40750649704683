import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
interface paymentStateTypes {
  totalPayment: number;
}
const initialState: paymentStateTypes = {
  totalPayment: 0,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setTotalPayment(state, action: PayloadAction<number>) {
      state.totalPayment = action.payload;
    },
  },
});

// Actions
export const paymentActions = paymentSlice.actions;
// Selectors
export const selectTotalPayment = (state: RootState) => state;

// Reducer
const paymentReducer = paymentSlice.reducer;
export default paymentReducer;
