import { Input, Popconfirm, Dropdown, Space, Select } from "antd";
import React, { useEffect, useState } from "react";
import * as cts from "../../constants/ui/svg";
import Avatar from "../../assets/images/UserAva.png";
import i18n from "locales/i18n";
import { DownOutlined } from "@ant-design/icons";
import liveIcon from "../../assets/images/liveIcon.png";
import type { MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
export default function FooterComponent() {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const pathName = window.location.pathname;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const languages = [
    { value: "ja", label: i18n.t("JAPANESE") },
    // { value: "en", label: "English" },
  ];
  const [lang, setLang] = useState("ja");

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  const handleChange = (e: any) => {
    setLang(e);
    const nowLanguage = localStorage.getItem("lang") || "ja";
    localStorage.setItem("lang", nowLanguage === "en" ? "ja" : "en");
    window.location.reload();
  };
  // const handleChange = (e: any) => {
  //   setLang(e);
  //   let loc = "https://host-live.host-collection.com/";
  //   window.location.replace(loc + "?lng=" + e);
  // };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // useEffect(() => {}, [window.location.pathname]);
  return (
    <div
      className={`${
        pathName === "/livestream" && "hidden"
      } bg-[#EAECF0] max-800px:hidden max-500px:pt-[24px] pt-[40px] items-center justify-center w-full flex`}
    >
      <div className="size_content_hd w-full flex flex-col">
        <div className="flex items-center m max-500px:w-full max-500px:flex-col max-500px:justify-center max-500px:px-[16px] justify-between px-[40px]">
          <div className="flex flex-col w-[282px] max-500px:items-center">
            <img
              className="h-[40px] max-500px:h-[30px] w-[155px] mb-[8px] max-500px:w-[117px]"
              src={liveIcon}
            />
            {/* <span>{cts.liveIcon}</span> */}
            <span className="text-[14px] text-[#101828] notosans_normal">
              {i18n.t("A_WEBSITE_LIVE")}
            </span>
          </div>
          <div className="flex flex-col items-start">
            <div
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/terms-of-service");
              }}
              className="text-[14px] notosans_normal cursor-pointer"
            >
              利用規約
            </div>
            <div
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/privacy-policy");
              }}
              className="text-[14px] notosans_normal mt-[16px] cursor-pointer"
            >
              プライバシーポリシー
            </div>
            <div
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/support-contact");
              }}
              className="text-[14px] notosans_normal mt-[16px] cursor-pointer"
            >
              お問い合わせ
            </div>
          </div>
          {width <= 500 && <div className="w-full my-[16px]  h-px bg-[#98A2B333]"></div>}
          <div className="flex flex-col w-[180px] max-500px:w-full max-500px:items-center">
            {/* <span className="border-[#98A2B3] max-500px:border-none border-b pb-[12px] text-[14px] text-[#101828] notosans_normal">
              {i18n.t("FAQ")} <span className="ml-[5px]">{i18n.t("INQUIRY")}</span>
            </span> */}
            <div className="max-500px:flex max-500px:items-center mt-[8px]">
              {/* <span className="mt-[12px] max-500px:my-[0px] max-500px:mr-[12px] mb-[8px] notosans_bold text-[#101828] text-[14px]">
                {i18n.t("LANGUAGE")}
              </span> */}
              <Select
                // onChange={handleChange}
                className="slc_language mt-[8px]"
                defaultValue={lang}
                style={{ width: 179, height: 38 }}
                options={languages}
              />
            </div>
          </div>
        </div>
        {width <= 500 && (
          <div className="w-full mt-[20px] mb-[8px] mx-[16px] h-px bg-[#98A2B333]"></div>
        )}

        <div className="py-[20px] max-500px:mt-[0px] max-500px:pt-[0px] mt-[20px] flex items-center max-500px:border-none border-t border-[#98A2B3] justify-center text-[#101828] max-500px:text-[12px] max-500px:text-[#667085] text-[14px] notosans_normal">
          Copyright © 2024 by HOST LIVE
        </div>
      </div>
    </div>
  );
}
