import * as cts from "../../../constants/ui/svg";
import i18n from "locales/i18n";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import vid from "../../../assets/images/vid.png";
import vid1 from "../../../assets/images/vid1.png";
import vid2 from "../../../assets/images/vid2.png";
import img1 from "../../../assets/images/img1.png";
import img2 from "../../../assets/images/img2.png";
import img3 from "../../../assets/images/vid3.png";
import vid6 from "../../../assets/images/vid6.png";
import img4 from "../../../assets/images/img4.png";
import vid8 from "../../../assets/images/vid8.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useState } from "react";
import { useAppSelector } from "app/hooks";
import { URL_UPLOAD_IMAGE } from "../../../constants";
interface Props {}
const TabImage: React.FC<Props> = ({}) => {
  const [typeImg, setTypeImg] = useState(1);
  const listGallery = useAppSelector((state) => state.gallery.listGallery);

  const arrImg = [
    { img: vid8 },
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img1 },
  ];
  return (
    <div className=" bg-white flex flex-col max-500px:px-4 mb-[24px]">
      {/* <div className="flex items-center my-5 justify-center max-500px:hidden">
        <span
          onClick={() => setTypeImg(1)}
          className={`${
            typeImg === 1 ? "bg-[#EF6820] text-white" : "bg-[#F2F4F7] text-[#101828]"
          } cursor-pointer rounded-[8px] h-[40px] flex items-center px-[16px] text-[13px] notosans_medium`}
        >
          {i18n.t("ALL")}
        </span>
        <span
          onClick={() => setTypeImg(2)}
          className={`${
            typeImg === 2 ? "bg-[#EF6820] text-white" : "bg-[#F2F4F7] text-[#101828]"
          } cursor-pointer ml-[8px] rounded-[8px] h-[40px] flex items-center px-[16px] text-[13px] notosans_medium`}
        >
          {i18n.t("PHOTOGRAPH")}
        </span>
        <span
          onClick={() => setTypeImg(3)}
          className={`${
            typeImg === 3 ? "bg-[#EF6820] text-white" : "bg-[#F2F4F7] text-[#101828]"
          } cursor-pointer ml-[8px] rounded-[8px] h-[40px] flex items-center px-[16px] text-[13px] notosans_medium`}
        >
          {i18n.t("VIDEO")}
        </span>
      </div> */}
      <div className="grid grid-cols-5 mt-[20px] gap-4 max-1400px:grid-cols-4 max-1080px:!grid-cols-3 max-500px:!grid-cols-2 max-500px:gap-3 max-500px:py-4">
        {listGallery?.map((x: any, y) => {
          return (
            <img
              key={y}
              className="rounded-[8px] object-cover w-full h-full"
              src={`${URL_UPLOAD_IMAGE}/gallery-streamer/${x?.image}`}
            />
          );
        })}
      </div>
      {/* <div className="flex items-center justify-center cursor-pointer mt-[20px]">
        <span className="text-center text-[13px] text-[#101828] notosans_medium mr-[5px]">
          {i18n.t("SHOW_MORE")}
        </span>
        <span>{cts.showMore}</span>
      </div> */}
    </div>
  );
};
export default TabImage;
