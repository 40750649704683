import {
  createSearchParams,
  useNavigate,
  useParams,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import Cookies from "js-cookie";
import { API_URL_BASE } from "../../constants";
import { errorToast, formatMoney, successToast } from "helpers";
import * as svg from "../../constants/ui/svg";
import { getUserInfo } from "slices/userSlice";
import { Button } from "antd";
import { getToken } from "helpers/Token";

const PaymentSquarePage = () => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  // const totalMoneyCookies = Cookies.get("totalMoney");
  const totalPayment = useAppSelector((state) => state.payment.totalPayment);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const dispatch = useAppDispatch();
  const [tokenUser, setTokenUser] = useState<any>(getToken() as String);
  const [total, setTotal] = useState<any>();
  useEffect(() => {
    const totalPayment = localStorage.getItem("total_payment");
    if (totalPayment) {
      setTotal(totalPayment);
    } else {
      setTotal(0);
    }
  }, []);

  const paymentBrand = [
    svg.visaPayment,
    svg.mastercardPayment,
    svg.JCBPayment,
    svg.amexPayment,
    svg.DinersPayment,
  ];
  return (
    <>
      {userInfo?._id && (
        <div className="flex flex-col pt-[10px] px-[10%] pb-[40px] mt-[2px] bg-white max-500px:px-4">
          <div className="max-500px:hidden">
            <BreadcrumbComponent breadMain={i18n.t("PURCHASE_POINT")} breadChild="Square Payment" />
          </div>
          <div className="w-full flex flex-col items-center justify-center mt-[30px] max-500px:mt-3">
            {/* <span className="text-[20px] ">
          Money:{totalMoneyCookies}
          {i18n.t("YEN")}{" "}
        </span> */}
            <div className="flex flex-col text-sm  notosans_normal w-[40%] border-b border-b-[#EAECF0] pb-3 mb-3 max-500px:w-full">
              <span className="notosans_medium">クレジットカード即時決済</span>
              <span>選択された請求書について、 今すぐクレジットカードで支払うことができます。</span>
              <span>
                決済を行うクレジットカードを選択し、
                セキュリティコードを入力して「Pay」ボタンを押してください。
              </span>
            </div>
            <div className="flex border-b  justify-between items-center border-b-[#EAECF0] pb-3 mb-3 w-[40%] max-500px:w-full">
              <span className="text-base notosans_normal ">お支払い金額</span>
              <span className="text-2xl notosans_bold flex flex-1 justify-end">
                {formatMoney(total)}
              </span>
            </div>
            <div className="flex border-b  justify-between items-center border-b-[#EAECF0] pb-3 mb-3 w-[40%] max-500px:w-full">
              <span className="text-base notosans_normal text-textPrimary">カードブランド</span>
              <div className="flex flex-1 justify-end items-center gap-2">
                {paymentBrand.map((item, index) => (
                  <div className="svg_payment" key={index}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className=" form-square">
              <PaymentForm
                applicationId="sandbox-sq0idb-JQORKwYpK0L6tTgIzH1eaQ" // Sandbox
                // applicationId="sq0idp-4JBwGzUH6bX5hjJ1lFlscQ" // Production
                cardTokenizeResponseReceived={async (token: any, buyer: any) => {
                  const response = await fetch(`${API_URL_BASE}/process-square`, {
                    // const response = await fetch(`http://127.0.0.1:3003/api/process-square`, {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json",
                    },
                    body: JSON.stringify({
                      sourceId: token.token,
                      value: total,
                      user: userInfo?._id,
                    }),
                  });

                  if (!response?.ok) {
                    errorToast("カード情報を間違います");
                    // setTimeout(() => {
                    // 	router.back();
                    // }, 1000);
                    return;
                  }
                  const data = await response.json();
                  if (data.payment.status === "COMPLETED") {
                    successToast("支払完了");
                    localStorage.setItem("total_payment", total.toString());

                    setTimeout(() => {
                      navigate("/payment-success");
                      dispatch(getUserInfo({ token: tokenUser }));
                    }, 1000);
                    // localStorage.setItem("total_payment", valueCoin.point.toString());

                    // 	const test = await updateOrderApi(tokenMain as string, params);
                    // 	sendMailOrderApi({ tokenMain, id: orderId });
                    // 	dispatch(cartAction.paymentSuccess());
                    // 	localStorage.setItem('ORDER_ID', orderId.toString());
                    // } else {
                    // 	console.log('error ');
                    // 	errorToast('Payment Error');
                    // 	setTimeout(() => {
                    // 		router.back();
                    // 	}, 1000);
                    // }
                  }
                }}
                locationId="LHDVVMPP87H6Z" //Locations Sanbox
                // locationId="ZDZNDFJBCZJ53" //Production
              >
                <CreditCard />
              </PaymentForm>
            </div>
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                navigate(-1);
              }}
              className="hover:!text-[#667085] mt-[15px] w-[40%] hover:!border-[#667085] border-[#667085] text-[#667085] shadow-[0px_1px_2px_0px_#1018280D] h-[48px] rounded-[8px] flex items-center justify-center text-[16px] notosans_bold"
            >
              {i18n.t("CANCEL")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default PaymentSquarePage;
