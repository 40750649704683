import { call, put, select, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

import i18n from "../locales/i18n";

import { toast, errorToast } from "../helpers";

import { showLoading } from "../slices/commonSlice";
import {
  getFollowAPI,
  changeFollowAPI,
  getPointLiveStreamAPI,
  getUserOfShopAPI,
  getDetailHostAPI,
} from "../apis";
import * as act from "../slices/followSlice";
import { FollowParams, FollowState, PointLiveState } from "../types/follow";
import { UserOfShopParams, UserOfShopState } from "types/userOfShop";

function* getFollowSaga(action: PayloadAction<FollowParams>) {
  try {
    const res: AxiosResponse<Array<FollowState>> = yield call(getFollowAPI, action.payload);
    if (res.data) {
      yield put(act.getFollowSuccess(res.data));
      yield put(showLoading(false));
    } else {
      console.log(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    // errorToast(i18n.t("GET_ERROR"));
    console.log("error", i18n.t("GET_ERROR"));

    yield put(showLoading(""));
  }
}
function* changeFollowSaga(action: PayloadAction<FollowParams>) {
  try {
    const res: AxiosResponse<FollowState> = yield call(changeFollowAPI, action.payload);
    if (res.data) {
      yield put(act.changeFollowSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error: any) {
    errorToast(error);
  }
}
function* changeFollowInShopSaga(action: PayloadAction<FollowParams>) {
  try {
    const res: AxiosResponse<FollowState> = yield call(changeFollowAPI, action.payload);
    if (res.data) {
      yield put(act.changeFollowInShopSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error: any) {
    errorToast(error);
  }
}
function* getPointLiveStreamSaga(action: PayloadAction<{ userID: any }>) {
  try {
    const res: AxiosResponse<any> = yield call(getPointLiveStreamAPI, action.payload);
    if (res.data) {
      yield put(act.getPointLiveStreamSuccess(res.data));
      yield put(showLoading(false));
    } else {
      console.log(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    // errorToast(i18n.t("GET_ERROR"));
    console.log("error", i18n.t("GET_ERROR"));

    yield put(showLoading(""));
  }
}
function* getUserOfShopSaga(action: PayloadAction<UserOfShopParams>) {
  try {
    const res: AxiosResponse<Array<UserOfShopState>> = yield call(getUserOfShopAPI, action.payload);
    if (res.data) {
      yield put(act.getUserOfShopSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error: any) {
    errorToast(error.message);
  }
}
function* getDetailHostSaga(action: PayloadAction<string>) {
  try {
    const res: AxiosResponse<UserOfShopState> = yield call(getDetailHostAPI, action.payload);
    yield put(act.getDetailHostSuccess(res.data));
  } catch (error: any) {
    errorToast(error.message);
  }
}
export default function* followWatcher() {
  yield takeEvery(act.getFollow, getFollowSaga);
  yield takeEvery(act.changeFollow, changeFollowSaga);
  yield takeEvery(act.getPointLiveStream, getPointLiveStreamSaga);
  yield takeLatest(act.getUserOfShop, getUserOfShopSaga);
  yield takeEvery(act.getDetailHost, getDetailHostSaga);
  yield takeLatest(act.changeFollowInShop, changeFollowInShopSaga);
}
