import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { FollowInShopState, FollowParams, FollowState, PointLiveState } from "types/follow";
import { UserOfShopParams, UserOfShopState } from "types/userOfShop";
export interface FollowStateTypes {
  changeFollow: FollowState;
  listFollow: Array<FollowState>;
  listFollowInStream: Array<FollowState>;
  listRankingGift: Array<PointLiveState>;
  listHost: Array<UserOfShopState>;
  detailHost: UserOfShopState;
  listFollowInShop: Array<FollowInShopState>;
}
const initialState: FollowStateTypes = {
  changeFollow: {},
  listFollow: [],
  listFollowInStream: [],
  listRankingGift: [],
  listHost: [],
  detailHost: {},
  listFollowInShop: [
    {
      streamer: "",
      followers: [],
    },
  ],
};

const followSlice = createSlice({
  name: "follow",
  initialState,
  reducers: {
    getDetailHost(state: any, action: PayloadAction<string>) {},

    getDetailHostSuccess(state: any, action: PayloadAction<UserOfShopState>) {
      state.detailHost = action.payload;
    },
    getUserOfShop(state: any, action: PayloadAction<UserOfShopParams>) {},

    getUserOfShopSuccess(state: any, action: PayloadAction<Array<UserOfShopState>>) {
      state.listHost = action.payload;
    },
    getFollow(state: any, action: PayloadAction<FollowParams>) {},

    getFollowSuccess(state: any, action: PayloadAction<Array<FollowState>>) {
      state.listFollow = action.payload.filter((x: any) => x?.streamer !== null);
      state.listFollowInStream = action.payload.filter((x: any) => x?.streamer !== null);
      let result1 = action.payload
        .filter((x: any) => x?.streamer !== null)
        .filter((y: any) => y?.follower !== null)
        .reduce(function (accObj: any, currentObj: any) {
          accObj[currentObj.streamer._id] = accObj[currentObj.streamer._id] || [];
          accObj[currentObj.streamer._id].push(currentObj);
          return accObj;
        }, {});
      state.listFollowInShop = Object.keys(result1).map((k) => ({
        streamer: k,
        followers: result1[k],
      }));
    },
    getPointLiveStream(state: any, action: PayloadAction<{ userID: string }>) {},
    getPointLiveStreamSuccess(state: any, action: any) {
      state.listRankingGift = action.payload;
    },
    changeFollow(state: any, action: PayloadAction<FollowParams>) {},
    changeFollowSuccess(state: any, action: PayloadAction<FollowState>) {
      const indexToUpdate = state.listFollowInShop?.findIndex(
        (x: any) => x?.streamer === action.payload?.streamer?._id
      );
      if (action.payload.status !== "disable") {
        state.listFollow = [...state.listFollow, action.payload];
        state.listFollowInStream = [...state.listFollowInStream, action.payload];
        state.detailHost = {
          ...state.detailHost,
          follower: [...(state.detailHost?.follower || []), action.payload.follower],
        };
        const IndexList = state.listHost.findIndex(
          (x: any) => x._id === action.payload.streamer?._id
        );
        if (IndexList >= 0) {
          state.listHost = state.listHost.map((item: any, idx: any) =>
            idx === IndexList
              ? {
                  ...item,
                  follower: [...state.listHost[IndexList].follower, action.payload.follower],
                }
              : item
          );
        }
      } else {
        state.listFollow = state.listFollow.filter(
          (x: any) => x.streamer._id !== action.payload?.streamer?._id
        );
        state.listFollowInStream = state.listFollowInStream?.filter(
          (x: any) => x.follower._id !== action.payload?.follower?._id
        );
        state.detailHost = {
          ...state.detailHost,
          follower: state.detailHost?.follower?.filter(
            (x: any) => x._id !== action.payload.follower?._id
          ),
        };
        state.detailHost?.follower?.filter((x: any) => x?._id !== action.payload.follower?._id);

        const IndexList = state.listHost.findIndex(
          (x: any) => x._id === action.payload.streamer?._id
        );
        if (IndexList >= 0) {
          state.listHost = state.listHost.map((item: any, idx: any) =>
            idx === IndexList
              ? {
                  ...item,
                  follower: state.listHost[IndexList].follower.filter(
                    (x: any) => x._id !== action.payload.follower?._id
                  ),
                }
              : item
          );
        }
      }
    },
    changeFollowInShop(state: any, action: PayloadAction<FollowParams>) {},
    changeFollowInShopSuccess(state: any, action: PayloadAction<FollowState>) {
      const indexToUpdate = state.listFollowInShop?.findIndex(
        (x: any) => x?.streamer === action.payload?.streamer?._id
      );
      if (action.payload.status !== "disable") {
        if (indexToUpdate !== -1 && indexToUpdate !== undefined) {
          const updatedFollowers = [
            ...(state.listFollowInShop[indexToUpdate]?.followers || []),
            ...(action.payload?.follower ? [action.payload] : []),
          ];
          state.listFollowInShop = [
            ...state.listFollowInShop.slice(0, indexToUpdate),
            {
              ...state.listFollowInShop[indexToUpdate],
              followers: updatedFollowers,
            },
            ...state.listFollowInShop.slice(indexToUpdate + 1),
          ];
        }
      } else {
        if (indexToUpdate !== -1 && indexToUpdate !== undefined) {
          const updatedFollowers =
            current(state.listFollowInShop[indexToUpdate])?.followers?.filter(
              (item: any) => item.follower?._id !== action.payload?.follower?._id
            ) || [];
          state.listFollowInShop = current(state.listFollowInShop)?.map((item: any, idx: number) =>
            idx === indexToUpdate
              ? {
                  ...item,
                  followers: updatedFollowers,
                }
              : item
          );
        }
      }
    },
  },
});

// Actions
export const {
  getUserOfShop,
  getUserOfShopSuccess,
  getFollow,
  getFollowSuccess,
  changeFollow,
  changeFollowSuccess,
  getPointLiveStream,
  getPointLiveStreamSuccess,
  getDetailHost,
  getDetailHostSuccess,
  changeFollowInShop,
  changeFollowInShopSuccess,
} = followSlice.actions;
export const followSelector = (state: RootState) => state;
// Reducer
export default followSlice.reducer;
