import { Button, Form, Input } from "antd";
import { useAppDispatch, useAppSelector } from "app/hooks";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { handleImageError } from "helpers";
import { getToken } from "helpers/Token";
import i18n from "locales/i18n";
import { useNavigate } from "react-router-dom";
import { deleteAccount } from "slices/userSlice";
import { URL_UPLOAD_IMAGE } from "../../../constants";

type FieldType = {
    password?: string;
};

function DeleteAccountPage() {
    const navigator = useNavigate();
    const dispatch = useAppDispatch();
    const token = getToken();
    const userInfo = useAppSelector((state) => state.users.userInfo);

    const onFinishLogin = (values: any) => {
        if (values?.password && token) {
            dispatch(
                deleteAccount({
                    token: token,
                    password: values?.password,
                })
            );
        }
    };

    const onFinishFailedLogin = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    return (
        <div className="deleteAccount bg-[#F2F4F7] max-w-[1060px] px-[30px] m-auto flex flex-col justify-start items-center pt-[10px]" style={{ minHeight: 'calc(100vh - 297px)' }}>
            <div className="w-full flex justify-start items-center mb-[10px]">
                <BreadcrumbComponent breadMain={i18n.t("MY_ACCOUNT")} breadChild={i18n.t("DELETE_ACCOUNT")} />
            </div>
            <h2 className="w-full text-left notosans_bold mb-[40px] text-[24px] leading-[36px] text-[#1D2939]">{i18n.t("ACCOUNT_DELECTION")}</h2>
            <div className="form w-full bg-white max-w-[588px] min-h-[410px] rounded-[10px] flex flex-col justify-start items-center gap-[20px] px-[40px] py-[20px] border-[1px] border-solid border-[#D0D5DD]">
                <div className="flex flex-col justify-start items-center gap-[8px] min-h-[110px]">
                    <img src={userInfo?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${userInfo?.avatar}` : ''} alt="" onError={handleImageError} className="w-[50px] h-[50px] rounded-full border-solid border-[.5px] border-[#D0D5DD]" />
                    <span className="username text-[20px] notosans_bold leading-[28px] text-[#1D2939]">{userInfo.userName}</span>
                    <span className="email text-[16px] leading-[20px] notosans_normal text-[#1D2939]">{userInfo.email}</span>
                </div>
                <span className="min-h-[36px]  notosans_normal text-[14px] leading-[18px] text-center text-[#1D2939]">{i18n.t("ARE_YOU_SURE_DELETE_ACCOUNT")}</span>
                <span className="min-h-[18px]  notosans_normal text-[14px] leading-[18px] text-center text-[#000000]">{i18n.t("ENTER_PASSWORD_TO_DELETE_ACCOUNT")}</span>
                <Form
                    name="login"
                    className="w-full flex flex-col justify-start items-start gap-[20px]"
                    onFinish={onFinishLogin}
                    onFinishFailed={onFinishFailedLogin}
                    autoComplete="off"
                >
                    <label htmlFor="password" className="w-full h-[66px] flex flex-col justify-start items-start gap-[8px]">
                        <span className="notosans_medium text-[14px] leading-[18px] text-black">{i18n.t("PASSWORD")}</span>
                        <Form.Item<FieldType>
                            name="password"
                            className="hide_pass_sign w-full !border-[#D0D5DD] focus:border-[#D0D5DD]"
                            rules={[{ required: true, message: i18n.t("PLEASE_INPUT_YOUR_PASSWORD") }]}
                        >
                            <Input.Password
                                className="h-[40px] rounded-[12px] outline-none focus-within:outline-none focus:border-[#D0D5DD]"
                                placeholder={i18n.t("PASSWORD")}
                            />
                        </Form.Item>

                    </label>
                    <span className="line relative w-full h-[1px] bg-[#D0D5DD]"> </span>
                    <div className="btns w-full h-[40px] flex justify-start items-center ">
                        <Form.Item className="flex-1 mr-[8px] !mb-[0px]">
                            <Button onClick={() => {
                                navigator('/my-account')
                            }}
                                className="bg-white w-full hover:!text-[#475467] hover:bg-white h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-[#475467] border-[1px] border-solid !border-[#D0D5DD]"
                                htmlType="button"
                            >
                                {i18n.t("CANCEL")}
                            </Button>
                        </Form.Item>
                        <Form.Item className="flex-1 !mb-[0px]">
                            <Button
                                className="bg-[rgb(239,104,32)] w-full hover:border-none !border-[#EF6820] hover:!text-white hover:bg-[#EF6820] h-[40px] rounded-[8px] flex items-center justify-center text-[14px] notosans_bold text-white"
                                htmlType="submit"
                            >
                                {i18n.t("CONFIRM")}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default DeleteAccountPage;