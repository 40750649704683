import { call, put, select, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import i18n from "../locales/i18n";
import { toast, errorToast } from "../helpers";
import { showLoading } from "../slices/commonSlice";
import { getUserOfShopAPI, getDetailHostAPI, getReportAPI } from "../apis";
import * as act from "../slices/userOfShopSlice";
import { ReportParams, ReportState, UserOfShopParams, UserOfShopState } from "../types/userOfShop";



function* getReportSaga(action: PayloadAction<ReportParams>) {
  try {
    const res: AxiosResponse<ReportState> = yield call(getReportAPI, action.payload);
    if (res.data) {
      yield put(act.getReportSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error: any) {
    errorToast(error.message);
  }
}
export default function* userOfShopWatcher() {
  yield takeLatest(act.getReport, getReportSaga);
}
