import { call, put, select, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

import i18n from "../locales/i18n";

import { toast, errorToast } from "../helpers";

import { showLoading } from "../slices/commonSlice";
import { getShopAPI, getDetailShopAPI } from "../apis";
import * as act from "../slices/shopSlice";
import { ShopState, ShopParams } from "../types/shop";

function* getShopSaga(action: any) {
  try {
    const res: AxiosResponse<any> = yield call(getShopAPI);
    if (res.data) {
      yield put(act.getListShopSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    errorToast(i18n.t("GET_ERROR"));
    yield put(showLoading(""));
  }
}
function* getDetailShopSaga(action: PayloadAction<string>) {
  try {
    const res: AxiosResponse<ShopState> = yield call(getDetailShopAPI, action.payload);
    yield put(act.getDetailShopSuccess(res.data));
  } catch (error: any) {
    errorToast(error.message);

    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  }
}
export default function* shopWatcher() {
  yield takeEvery(act.getListShop, getShopSaga);
  yield takeEvery(act.getDetailShop, getDetailShopSaga);
}
