import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import i18n from "locales/i18n";
import * as cts from "../../constants/ui/svg";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { changeFollow } from "slices/followSlice";
import { errorToast } from "helpers";
import img from "../../assets/images/img.png";
type Props = {
  open?: boolean;
  setOpen?: any;
};

const ModalEventLive = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const listFollow = useAppSelector((state) => state.follow.listFollow);

  return (
    <Modal centered className="modal_event_live" open={open} onCancel={() => setOpen(false)}>
      <div className="flex">
        <img src={img} className="rounded-l-[8px]" />
        <div className="p-[32px] flex flex-col w-[400px]">
          <span className="text-[#1D2939] text-[18px] notosans_bold">
            イベントのタイトル。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
          </span>
          <span className="text-[16px] mt-[20px] text-[#1D2939] notosans_normal">
            イベントの内容。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するため。
          </span>
          <span className="mt-[20px] text-[16px] text-[#1D2939] notosans_medium">時間:</span>
          <span className="text-[16px] text-[#1D2939] notosans_normal">
            2024年7月7日 ー 21:00 ~ 23:00
          </span>
          <span className="mt-[20px] text-[16px] text-[#1D2939] notosans_medium">配信リンク:</span>
          <span className="text-[16px] notosans_normal text-[#2970FF]">
            https://host-live.host-collection.com//livestream
          </span>
        </div>
      </div>
    </Modal>
  );
};
export default ModalEventLive;
