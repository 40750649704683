import { call, put, select, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

import i18n from "../locales/i18n";

import { toast, errorToast } from "../helpers";

import { showLoading } from "../slices/commonSlice";
import { getEventLiveAPI,getDetailEventLiveAPI} from "../apis";
import * as act from "../slices/eventLiveSlice";
import { EventLiveParams, EventLiveState } from "../types/eventLive";

function* getEventLiveSaga(action: PayloadAction<EventLiveParams>) {
  try {
    const res: AxiosResponse<Array<EventLiveState>> = yield call(getEventLiveAPI, action.payload);
    if (res.data) {
      yield put(act.getEventLiveSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    errorToast(i18n.t("GET_ERROR"));
    yield put(showLoading(""));
  }
}
function* getDetailEventLiveSaga(action: PayloadAction<string>) {
  try {
    const res: AxiosResponse<EventLiveState> = yield call(getDetailEventLiveAPI, action.payload);
    if (res.data) {
      yield put(act.getDetailEventSuccess(res.data));
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    errorToast(i18n.t("GET_ERROR"));
    yield put(showLoading(""));
  }
}
export default function* eventLiveWatcher() {
  yield takeLatest(act.getEventLive, getEventLiveSaga);
  yield takeEvery(act.getDetailEvent, getDetailEventLiveSaga);

}
