import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import vid from "../../assets/images/noAva.png";
import vid1 from "../../assets/images/HOST IMAGE/pic03 (2).jpg";
import vid2 from "../../assets/images/HOST IMAGE/pic03 (3).jpg";
import vid3 from "../../assets/images/HOST IMAGE/pic03 (4).jpg";
import vid4 from "../../assets/images/HOST IMAGE/pic03 (5).jpg";
import vid5 from "../../assets/images/HOST IMAGE/pic03 (6).jpg";
import vid6 from "../../assets/images/HOST IMAGE/pic03 (7).jpg";
import vid7 from "../../assets/images/HOST IMAGE/pic03 (8).jpg";
import vid8 from "../../assets/images/HOST IMAGE/pic03 (9).jpg";
import vid9 from "../../assets/images/HOST IMAGE/pic03.jpg";
import noAva from "../../assets/images/noAva.png";

import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import { useCallback, useEffect, useRef, useState } from "react";
import BreadcrumbComponent from "components/Common/Breadcrumb";
import { useAppSelector } from "app/hooks";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { getFollow, getUserOfShop } from "slices/followSlice";
import ButtonFollowComponent from "components/Common/ButtonFollow";
import { URL_UPLOAD_IMAGE } from "../../constants";
const PickupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const channelsSt = useAppSelector((state) => state.channels.channels);
  const listHost = useAppSelector((state) => state.follow.listHost);
  const listFollow = useAppSelector((state) => state.follow.listFollow);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const nominationArr = [
    { ava: vid, name: "ダヌジャ", live: true, follow: false },
    { ava: vid1, name: "ダヌジャ", live: false, follow: true },
    { ava: vid2, name: "ダヌジャ", live: false, follow: false },
    { ava: vid3, name: "ダヌジャ", live: true, follow: true },
    { ava: vid4, name: "ダヌジャ", live: false, follow: false },
    { ava: vid5, name: "ダヌジャ", live: true, follow: false },
    { ava: vid6, name: "ダヌジャ", live: false, follow: false },
    { ava: vid7, name: "ダヌジャ", live: false, follow: true },
    { ava: vid8, name: "ダヌジャ", live: true, follow: false },
    { ava: vid4, name: "ダヌジャ", live: true, follow: false },
    { ava: vid3, name: "ダヌジャ", live: false, follow: true },
    { ava: vid2, name: "ダヌジャ", live: true, follow: false },
    { ava: vid, name: "ダヌジャ", live: true, follow: false },
  ];
  useEffect(() => {
    dispatch(getUserOfShop({ type: "get_all" }));
  }, []);
  useEffect(() => {
    if (userInfo._id && listFollow?.length == 0) {
      dispatch(getFollow({ follower: userInfo._id, type: "get_all" }));
    }
  }, [userInfo._id]);
  return (
    <div className="flex pt-[10px] justify-center pb-[40px] mt-[2px] pickup_video">
      <div className="size_content_hd w-full flex-col flex">
        <div className="">
          <BreadcrumbComponent breadMain={i18n.t("PICK_UP")} />
        </div>

        <span className="text-[#101828] mt-[10px] text-[24px] notosans_bold max-500px:text-lg max-500px:font-bold">
          {i18n.t("PICK_UP")}
        </span>
        {/* <div className="flex items-center justify-center notosans_thin pb-[100px] w-full">
          {i18n.t("NO_DATA")}
        </div> */}
        <div className="grid grid-cols-6 gap-4 mt-[20px] mb-[20px] max-800px:!grid-cols-3 max-1080px:grid-cols-4 max-500px:!grid-cols-2">
          {listHost?.map((x, y) => {
            return (
              <div
                key={y}
                className="flex flex-col cursor-pointer items-center justify-center my-[15px]"
              >
                <div
                  onClick={() => {
                    window.scrollTo(0, 0);
                    if (channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id) {
                      navigate({
                        pathname: "/livestream",
                        search: createSearchParams({
                          key: `${y}`,
                        }).toString(),
                      });
                    } else {
                      navigate(
                        {
                          pathname: "/streamer-profile",
                          search: createSearchParams({
                            key: `${x?._id}`,
                          }).toString(),
                        },
                        {
                          state: {
                            prevPath: i18n.t("PICK_UP"),
                          },
                        }
                      );
                    }
                  }}
                  // className="w-[120px] h-[120px] rounded-[50%]"
                  className={`${
                    channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id &&
                    "border-[4px] border-[#F04438] relative flex items-end justify-center"
                  } w-[120px] h-[120px] rounded-[50%]`}
                >
                  {channelsSt?.find((i: any) => i?.user?._id.includes(x?._id))?.user?._id && (
                    <span className="icon_live_profile absolute">{cts.live1Icon}</span>
                  )}
                  <img
                    className="w-[120px] h-[120px] rounded-[50%] object-cover"
                    src={x?.avatar ? `${URL_UPLOAD_IMAGE}/avatars/${x?.avatar}` : noAva}
                  />
                </div>
                <span
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate(
                      {
                        pathname: "/streamer-profile",
                        search: createSearchParams({
                          key: `${x?._id}`,
                        }).toString(),
                      },
                      {
                        state: {
                          prevPath: i18n.t("PICK_UP"),
                        },
                      }
                    );
                  }}
                  className="text-[#0C111D] text-[16px] notosans_normal mt-[8px] max-500px:text-sm"
                >
                  {x?.nickName || x?.userName}
                </span>
                <span className="text-[#667085] mb-[8px] text-[12px] notosans_normal mt-[4px]">
                  {x?.follower?.length || 0} {i18n.t("FOLLOWER")}
                </span>
                <ButtonFollowComponent idStreamer={x?._id} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default PickupPage;
