import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import i18n from "locales/i18n";
import {
  ChangePasswordParams,
  LoginProps,
  RegisterUserProps,
  StatusHandlerProps,
  UserParams,
  UserProps,
  UserState,
} from "../types/user";

const initialState: UserState = {
  token: "",
  userInfo: {} as UserProps,
  status: {} as StatusHandlerProps,
  webHistoryPointUser: [],
};
export interface FormDataTypes {
  nickName: string;
  gender: string;
  description: string;
  file: any;
}
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login(state, action: PayloadAction<LoginProps>) {},
    loginSuccess(state, action: PayloadAction<{ access_token: string; user: any }>) {
      state.token = action.payload.access_token;
      state.userInfo = action.payload.user;
    },
    getTokenFlow(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    registerUser(state, action: PayloadAction<RegisterUserProps>) {},
    registerUserSuccess(state, action) {
      // state.userInfo = action.payload;
    },
    getUserInfo(state: any, action: PayloadAction<UserParams>) {
      // return {
      //   userInfo: action.payload
      // }
    },
    getUserInfoSuccess(state: any, action: PayloadAction<{ _id: string }>) {
      state.userInfo = action.payload;
    },
    updateUser(
      state,
      action: PayloadAction<{ token: String; id: String; formData: FormDataTypes }>
    ) {
      state.status = {
        action: "deleteAccount",
        status: 200,
        message: i18n.t("REMOVE_ACCOUNT_SUCCESS"),
      };
    },
    updateUserSuccess(state, action: PayloadAction<UserProps>) {
      state.userInfo = action.payload;
    },
    changePassword(state, action: PayloadAction<{ token: String; data: ChangePasswordParams }>) {},
    changePasswordSuccess(state, action: PayloadAction<UserProps>) {},
    deleteAccount(state, action: PayloadAction<{ token: String; password: string }>) {},
    deleteAccountSuccess(state) {
      Cookies.remove("token_webnomi");
      state.status = {
        action: "deleteAccount",
        status: 200,
        message: i18n.t("REMOVE_ACCOUNT_SUCCESS"),
      };
    },
    deleteAccountError(state) {
      state.status = {
        action: "deleteAccount",
        status: 401,
        message: i18n.t("PASSWORD_INCORRECT"),
      };
      window.location.reload();
    },
    deleteUserAvatar(state, action: PayloadAction<{ token: String; id: String, data: Object }>) {
    },
    deleteUserAvatarSuccess(state) {
      state.userInfo.avatar = "";
    },
    supportContact(state, action: PayloadAction<{ data: any }>) {},
    getWebHistoryUserPoint(stat, action: PayloadAction<{ params: any }>) {},
    getWebHistoryUserPointSuccess(state, action: PayloadAction<any>) {
      state.webHistoryPointUser = action.payload;
    },
  },
});

// Actions
export const {
  login,
  loginSuccess,
  getTokenFlow,
  getUserInfo,
  getUserInfoSuccess,
  registerUser,
  registerUserSuccess,
  updateUser,
  updateUserSuccess,
  changePassword,
  changePasswordSuccess,
  deleteAccount,
  deleteAccountSuccess,
  deleteAccountError,
  deleteUserAvatar, 
  deleteUserAvatarSuccess,
  //NgocNa
  supportContact,
  getWebHistoryUserPoint,
  getWebHistoryUserPointSuccess,
} = userSlice.actions;

// Reducer
export default userSlice.reducer;
