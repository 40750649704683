import i18n from "locales/i18n";
import * as cts from "../../../constants/ui/svg";
// Import Swiper styles
import { Input, Modal } from "antd";
import { useAppDispatch } from "app/hooks";
import { errorToast, removeAllWhitespace } from "helpers";
import { getToken } from "helpers/Token";
import { useState } from "react";
import { changePassword } from "slices/userSlice";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
interface Props {}
const TabPassword: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const token = getToken();
  const [forgotPass, setForgotPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const [changePass, setChangePass] = useState({
    password: "",
    passwordCurrent: "",
    confirmPassword: "",
  });

  const onChangePass = () => {
    if (
      changePass.password !== "" &&
      changePass.passwordCurrent !== "" &&
      changePass.confirmPassword !== "" &&
      changePass.confirmPassword === changePass.password
    ) {
      dispatch(
        changePassword({
          token: token as String,
          data: { password: changePass.password, passwordCurrent: changePass.passwordCurrent },
        })
      );
    } else {
      if (changePass.confirmPassword !== changePass.password) {
        errorToast("Confirm password does not match current password you want to change");
      }
      const value = "";
      switch (value) {
        case changePass.confirmPassword:
          errorToast("Please enter confirm password");
          break;
        case changePass.password:
          errorToast("Please enter current password");
          break;
        case changePass.passwordCurrent:
          errorToast("Please enter old password");
          break;
        default:
          return;
      }
    }
  };
  return (
    <div className="mb-[40px] bg-white rounded-[8px] p-[20px] flex flex-col max-500px:p-0 max-500px:m-4 ">
      <span className="text-[20px] notosans_bold text-[#101828] max-500px:hidden">
        {i18n.t("CHANGE_PASSWORD")}
      </span>
      <span className="text-[#475467] text-[14px] notosans_normal mt-[8px] max-500px:hidden">
        {i18n.t("CHANGE_UR_PASS")}
      </span>
      <div className="mt-[20px] border-b border-t border-[#EAECF0] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:border-t-0 max-500px:pb-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
        <span className="text-[#344054] max-1080px:col-span-2 text-[16px] notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1">
          {i18n.t("OLD_PASS")}
        </span>
        <Input.Password
          value={changePass.passwordCurrent}
          onChange={(e) => setChangePass({ ...changePass, passwordCurrent: removeAllWhitespace(e.target.value) })}
          className="h-[44px] col-span-2 rounded-[8px]"
          placeholder={i18n.t("PLS_ENTER_UR_OLD")}
          autoComplete="new-password"
        />
      </div>
      <div className="mt-[20px] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:py-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
        <span className="text-[#344054] max-1080px:col-span-2 text-[16px] notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1">
          {i18n.t("NEW_PASS")}
        </span>
        <Input.Password
          value={changePass.password}
          onChange={(e) => setChangePass({ ...changePass, password: removeAllWhitespace(e.target.value) })}
          className="h-[44px] col-span-2 rounded-[8px]"
          placeholder={i18n.t("PLS_ENTER_NEW_PASS")}
        />
      </div>
      <div className="mt-[20px] border-t border-[#EAECF0] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:py-3 max-500px:mt-0 max-500px:pt-0 max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
        <span className="text-[#344054] text-[16px] max-1080px:col-span-2 notosans_bold max-500px:text-sm max-500px:flex max-500px:items-center max-500px:py-1">
          {i18n.t("NEW_PASS")}({i18n.t("CONFIRM")})
        </span>
        <Input.Password
          value={changePass.confirmPassword}
          onChange={(e) => setChangePass({ ...changePass, confirmPassword: removeAllWhitespace(e.target.value) })}
          className="h-[44px] col-span-2 rounded-[8px]"
          placeholder={i18n.t("PLS_ENTER_NEW_PASS")}
        />
      </div>
      <div className="mt-[20px] border-b border-t border-[#EAECF0] py-[20px] items-center grid grid-cols-5 gap-4 max-500px:py-3 max-500px:mt-0  max-500px:flex max-500px:flex-col max-500px:justify-center max-500px:items-start max-500px:gap-2">
        <span className="text-[#344054] max-1080px:col-span-2 text-[16px] notosans_bold max-500px:hidden"></span>
        <span
          onClick={() => setForgotPass(true)}
          className="text-[#EF6820] max-1080px:col-span-2 text-[16px] notosans_medium cursor-pointer"
        >
          {i18n.t("FORGOT")}
        </span>
      </div>
      <div className="mt-[20px] items-center grid grid-cols-5 gap-4 max-500px:mt-3 max-500px:flex max-500px:w-full">
        <span className="text-[#344054] max-1080px:col-span-2 text-[16px] notosans_bold max-500px:hidden "></span>
        <div className="col-span-2 max-500px:w-full">
          <div
            onClick={() => onChangePass()}
            className="flex bg-[#EF6820] px-[16px] w-[123px] items-center text-[#FFFFFF] text-[13px] notosans_medium h-[40px] rounded-[8px] cursor-pointer max-500px:w-full max-500px:justify-center max-500px:item-center"
          >
            {i18n.t("CHANGE_PASSWORD")}
          </div>
        </div>
      </div>
      <Modal className="modal_forgot_pass  justify-center items-center" centered open={forgotPass}>
        <div onClick={() => setForgotPass(false)} className="flex items-end justify-end w-full">
          {cts.closeIcon1}
        </div>
        <span className="text-[#111927] text-[20px] notosans_bold">{i18n.t("RESET_PASS")}</span>
        <span className="mt-[20px] text-[#000000] text-[16px] notosans_normal">
          {i18n.t("WE_WILL_SEND_U_INF")}
        </span>
        <span className="text-[#000000] text-[16px] notosans_normal">
          {i18n.t("PLS_ENTER_UR_REGISTER")}
        </span>
        <div className="flex w-full justify-start">
          <span className="text-[#000000] text-[14px] notosans_bold mt-[20px] text-start">
            {i18n.t("EMAIL_ONLY")}
          </span>
        </div>
        <Input className="h-[40px] mt-[8px] rounded-[12px]" placeholder="例: user@mail.com" />
        <div
          onClick={() => {
            setForgotPass(false);
            setConfirmPass(true);
          }}
          className="bg-[#EF6820] w-full h-[40px] my-[20px] rounded-[8px] flex justify-center items-center text-white text-[14px] notosans_bold"
        >
          {i18n.t("SEND")}
        </div>
      </Modal>
      <Modal className="modal_forgot_pass  justify-center items-center" centered open={confirmPass}>
        <div onClick={() => setConfirmPass(false)} className="flex items-end justify-end w-full">
          {cts.closeIcon1}
        </div>
        <span className="text-[#111927] text-[20px] notosans_bold">{i18n.t("RESET_PASS")}</span>
        <span className="mt-[20px] text-[#000000] text-[16px] notosans_normal items-center text-center">
          <span className="notosans_bold">user@gmail.com</span> {i18n.t("WE_SEND_U_A_PASS_RESET")}
        </span>
        <span className="text-[#000000] text-[16px] notosans_normal my-[20px] items-center">
          {i18n.t("IF_U_DONT_RECEIVE")}
          <span className="text-[#EF6820] notosans_bold cursor-pointer ">
            {i18n.t("CLICK_TO_SEND")}
          </span>
        </span>
      </Modal>
    </div>
  );
};
export default TabPassword;
