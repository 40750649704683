import i18n from "locales/i18n";
import { useEffect } from "react";
import * as cts from "../../../constants/ui/svg";
function ModalDeleteSuccess(props: any) {
    const {backHome, onClose} = props;
    useEffect(() => {
        setTimeout(() => {
            onClose();
            backHome();
        }, 3000);
    })
    return ( <div className="flex flex-col justify-center items-center gap-[20px] px-[60px] py-[40px] rounded-[20px] w-[600px] h-[230px] bg-white">
        <span>{cts.iconSuccess}</span>
        <span className="notosans_bold text-[20px] leading-[30px] text-[#111927]">{i18n.t("REMOVE_ACCOUNT_SUCCESS")}</span>
    </div> );
}

export default ModalDeleteSuccess;