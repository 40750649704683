import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import i18n from "locales/i18n";
import { useAppSelector } from "app/hooks";
import { Link } from "react-router-dom";

type Props = {
  breadMain?: string;
  breadChild?: string;
  breadBefore?: string;
};

const BreadcrumbComponent: React.FC<Props> = ({ breadMain, breadChild, breadBefore }) => {
  const navigate = useNavigate();
  const pathName = window.location.pathname.substring(1);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  return (
    <div>
      <Breadcrumb
        items={[
          {
            title: (
              <Link className="link_home hover:bg-[#F2F4F7]" to="/">
                {i18n.t("HOME")}
              </Link>
            ),
          },
          {
            title: (
              <div className="flex items-center">
                {/* Bread Main */}
                <div
                  onClick={() => {
                    if (breadChild !== undefined) {
                      navigate(-1);
                    }
                  }}
                >
                  <span
                    className={`px-2 ${
                      breadChild !== undefined &&
                      "!text-[rgba(0,0,0,0.45)] hover:rounded-[4px] hover:!text-black hover:bg-[rgba(0,0,0,0.1)]"
                    }`}
                  >
                    {breadMain}
                  </span>
                </div>

                {/* Bread Before */}
                {breadBefore !== undefined && (
                  <div
                  onClick={() => {
                    if (breadChild !== undefined) {
                      navigate(-1);
                    }
                  }}
                  >
                  <span className="text-[12px] mx-[8px]">/</span>
                    <span
                      className={`${
                        breadBefore !== undefined &&
                        "!text-[rgba(0,0,0,0.45)] hover:rounded-[4px] hover:!text-black hover:bg-[rgba(0,0,0,0.1)]"
                      }`}
                    >
                      {breadBefore}
                    </span>
                  </div>
                )}

                {/* Bread Child */}
                {breadChild !== undefined && (
                  <div className="">
                    <span className="text-[12px] mx-[8px]">/</span>
                    {breadChild}
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default BreadcrumbComponent;
