import { useEffect, useState } from "react";
import noAvatar from "../assets/images/noAva.png";
// export function formatMoney(val: number | string) {
//   const locale = "ja-JP";
//   const options = { style: "currency", currency: "JPY" };
//   let value = `${val}`;
//   if (Number(val) >= 0) {
//     value = value.replace(/,/g, "");
//     const x = Number(value);
//     return `${x.toLocaleString(locale, options)}`;
//   } else {
//     value = value.replace(/[,-]/g, "");
//     const x = Number(value);
//     return `-${x.toLocaleString(locale, options)}`;
//   }
// }
export const formatMoney = (val: number | string) => {
  let value = `${val}`;
  value = value.replace(/,/g, "");
  const x = Number(value);
  return x.toLocaleString();
};

export function formatNumber(val: number | string) {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const useContainerDimensions = (myRef: any) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef?.current?.offsetWidth,
      height: myRef?.current?.offsetHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};
export const onViewLive = (idLive: any, nickNameNew: any) => {
  const localStoreData: any = localStorage.getItem("links") || [];
  if (localStoreData.length === 0) {
    localStorage.setItem("links", JSON.stringify([]));
    const newIdLive = [
      {
        id: idLive,
        nickNameNew: nickNameNew,
      },
    ];
    localStorage.setItem("links", JSON.stringify(newIdLive));
  } else {
    const localStoreDataObj = JSON.parse(localStoreData);
    const foundIdLive = localStoreDataObj?.find((item: any) => item.id === idLive);
    if (foundIdLive) {
      const localStoreDataObjFilter = localStoreDataObj.filter(
        (item: any) => item.id !== foundIdLive.id
      );
      const newIdLive = {
        id: idLive,
        nickNameNew: nickNameNew,
      };
      localStoreDataObjFilter.unshift(newIdLive);
      localStorage.setItem("links", JSON.stringify(localStoreDataObjFilter));
      return;
    } else {
      let localDTO = JSON.parse(localStoreData) || [];
      const newIdLive = {
        id: idLive,
        nickNameNew: nickNameNew,
      };
      localDTO.unshift(newIdLive);
      localStorage.setItem("links", JSON.stringify(localDTO));
    }
  }
};

export const countViews = (key: string, roomsInfo: any) => {
  if (key && roomsInfo?.length > 0) {
    const findRoom = roomsInfo.find((i: any) => i?.room === key);
    if (findRoom) {
      return findRoom?.countClients - 1 || 0;
    }
  }
  return 0;
};
export function formatNumberNew(val: number | string) {
  if (!val) {
    return 0;
  }
  if (Number(val) < 1000000) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (Number(val) < 1000000000) {
    return (Number(val) / 1000000).toFixed(2) + "M";
  } else {
    return (Number(val) / 1000000000).toFixed(2) + "B";
  }
}
export const containsNonWhitespaceCharacters = (input: string) => {
  const regex = /\S/;
  return regex.test(input);
};
export const removeAllWhitespace = (input: string) => {
  const regex = /\s+/g;
  return input.replace(regex, '');
};
export const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  e.currentTarget.src = noAvatar;
};
export const formatServerDateToDDMMYYYYHHMM = (isoDate: string) => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;

  return formattedDate;
}
export const getCurrentDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
export const getCurrentDateYYYYMM = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};
