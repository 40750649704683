import { call, put, select, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

import i18n from "../locales/i18n";
import { toast, errorToast } from "../helpers/index";

import { showLoading } from "../slices/commonSlice";
import { getChannelsAPI } from "../apis";
import * as act from "../slices/channelSlice";

function* getChannelsSaga(action: any) {
  yield put(showLoading("get-channels"));
  try {
    const res: AxiosResponse<any> = yield call(getChannelsAPI, action.payload);

    if (res.data) {
      yield put(act.fetchChannelsSuccess(res.data))
      yield put(showLoading(false));
    }else{
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    errorToast(i18n.t("GET_ERROR"));
    yield put(showLoading(""));
  }
}

export default function* userWatcher() {
  yield takeEvery(act.fetchChannels.type, getChannelsSaga);
  // yield takeLatest(act.login.type, loginSaga);
}