import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HistorySendGiftState, PaymentHistoryState } from "types/paymentHistory";

const initialState: PaymentHistoryState = {
    historySendGifts: [] as HistorySendGiftState[],
};

const paymentHistorySlice = createSlice({
    name: "gift",
    initialState,
    reducers: {
        getHistorySendGift(state, action: PayloadAction<{date: string | Date, userSend: string, token: string}>) {
        },
        getHistorySendGiftSuccess(state, action: PayloadAction<HistorySendGiftState[]>) {
            state.historySendGifts = action.payload;
        }
    },
})

export const {getHistorySendGift, getHistorySendGiftSuccess} = paymentHistorySlice.actions;
export default paymentHistorySlice.reducer;