import request from "./axiosService";

const URL_CHAT = "/chat";
const URL_POINT_LIVE = "/gift-points/get-point-live-stream";

// Api POS Demo
export function getChatsAPI(token: string, params: any) {
  return request({
    url: URL_CHAT,
    method: "get",
    token,
    params: { ...params, token: undefined },
  });
}
export function getPointLiveStreamAPI(params: { userID: string }) {
  return request({
    url: URL_POINT_LIVE,
    method: "get",
    params,
  });
}
