import { all } from "redux-saga/effects";
import paymentHistoryWatcher from "sagas/paymentHistorySaga";
import channelsSaga from "../sagas/channelSaga";
import chatSaga from "../sagas/chatSaga";
import eventLiveSaga from "../sagas/eventLiveSaga";
import followSaga from "../sagas/followSaga";
import galleryStreamerSaga from "../sagas/galleryStreamerSaga";
import giftNameSaga from "../sagas/giftNameSaga";
import liveStreamSaga from "../sagas/liveStreamSaga";
import shopSaga from "../sagas/shopSaga";
import userOfShopSaga from "../sagas/userOfShopSaga";
import userSaga from "../sagas/userSaga";

export default function* rootSaga() {
  yield all([
    channelsSaga(),
    chatSaga(),
    userSaga(),
    shopSaga(),
    userOfShopSaga(),
    followSaga(),
    eventLiveSaga(),
    giftNameSaga(),
    galleryStreamerSaga(),
    liveStreamSaga(),
    paymentHistoryWatcher()
  ]);
}
