import { io } from "socket.io-client";

import News from "../../assets/images/home-7675774_640.webp";
import vid from "../../assets/images/banner6.png";
import vid1 from "../../assets/images/banner7.png";
import vid2 from "../../assets/images/banner8.png";

import { useCallback, useEffect, useRef, useState } from "react";
import * as cts from "../../constants/ui/svg";
import i18n from "locales/i18n";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import PickupVideo from "./components/PickupVideo";
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import Recommended from "./components/Recommended";
import RankVideo from "./components/RankVideo";
import MediaView from "./components/MediaView";

// URLs
import { API_URL_BASE, SOCKET_SERVER } from "../../constants";

// Hooks
import { useAppDispatch, useAppSelector } from "app/hooks";
import { createChannelSuccess, fetchChannels, deleteChannelSuccess } from "slices/channelSlice";
// Components
import SocketPage from "../../socket";
import { getFollow } from "slices/followSlice";

const HomePage = () => {
  const dispatch = useAppDispatch();
  const channelsSt = useAppSelector((state) => state.channels.channels);
  const userInfo = useAppSelector((state) => state.users.userInfo);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  useEffect(() => {
    dispatch(fetchChannels({ channelName: "general" }));
  }, []);
  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    if (userInfo._id) {
      dispatch(getFollow({ follower: userInfo._id, type: "get_all" }));
    }
  }, [userInfo._id]);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  useEffect(() => {
    if (channelsSt.length === 0) {
      return;
    }
    const localData = JSON.parse(localStorage.getItem("links") || "[]");
    const newLocalData = localData
      .map((item: any) => {
        const foundId = channelsSt.find((i: any) => i?.user?._id === item.id);
        if (foundId) {
          return {
            ...item,
          };
        }
        return null;
      })
      .filter((i: any) => i);
    localStorage.setItem("links", JSON.stringify(newLocalData || []));
  }, [channelsSt?.length]);
  return (
    <div className="flex flex-col max-800px:bg-white">
      <div className="container max-800px:hidden !w-full py-[36px] px-[5%] max-1230px:py-[0px]">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={2}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={false}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            // clickable: true,
          }}
          modules={[EffectCoverflow, Navigation]}
          className="swiper_container"
        >
          <SwiperSlide>
            <img src={vid} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={vid1} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={vid2} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={vid1} alt="slide_image" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={vid2} alt="slide_image" />
          </SwiperSlide>

          <div className={`${width <= 1000 && "hidden"} slider-controler`}>
            <div className={`${width <= 1000 && "hidden"} swiper-button-prev cursor-pointer`}>
              {cts.leftVid}{" "}
            </div>
            <div className={`${width <= 1000 && "hidden"} swiper-button-next cursor-pointer`}>
              {cts.rightVid}
            </div>
          </div>
        </Swiper>
      </div>
      {width <= 800 && (
        <div className="mt-[12px]">
          <Swiper
            slidesPerView={1.5}
            spaceBetween={12}
            loop={true}
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            // modules={[Pagination, Navigation]}
            // className=" w-full mt-[20px] swiper_nomination swiper1"
          >
            <SwiperSlide>
              <img className="!h-[40vw] !w-[100vw] rounded-[8px] " src={vid} alt="slide_image" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="!h-[40vw] !w-[100vw] rounded-[8px]" src={vid1} alt="slide_image" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="!h-[40vw] !w-[100vw] rounded-[8px]" src={vid2} alt="slide_image" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="!h-[40vw] !w-[100vw] rounded-[8px]" src={vid} alt="slide_image" />
            </SwiperSlide>
            <SwiperSlide>
              <img className="!h-[40vw] !w-[100vw] rounded-[8px]" src={vid2} alt="slide_image" />
            </SwiperSlide>
          </Swiper>
        </div>
      )}
      <PickupVideo channels={channelsSt} />
      <Recommended />
      <RankVideo />
      <MediaView />
    </div>
  );
};

export default HomePage;
