import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  giftsName: <any>[]
};

const giftNameSlice = createSlice({
  name: "giftName",
  initialState,
  reducers: {
    fetchGiftNameList(state, action) {
    },
    fetchGiftNameListSuccess(state, action) {
      state.giftsName = action.payload;
    }
  }
});

// Action 
export const {
  fetchGiftNameList,
  fetchGiftNameListSuccess
} = giftNameSlice.actions;

// Reducer
const giftNameReducer = giftNameSlice.reducer;

export default giftNameReducer;
