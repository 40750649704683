
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  statusBarHeight: 0,
  language: process.env.NODE_ENV === 'development' ? 'en' : 'en',
  loading: undefined
};

const GeneralSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {

    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
      localStorage.setItem('language', action.payload);
    },

    showLoading(state, action: PayloadAction<string | undefined | boolean>) {
      state.loading = action.payload;
    },

    setNotifyInApp(
      state,
      action: PayloadAction<{ active: boolean; type: string; data: object }>,
    ) {
      state.notifyInApp = action.payload;
    },
  },
});

//actions
export const { setLanguage, showLoading } =
  GeneralSlice.actions;

//reducers
export default GeneralSlice.reducer;
