import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LiveStreamParams, LiveStreamState } from "types/liveStream";

interface liveStreamStateTypes {
  liveStream: LiveStreamState;
}
const initialState: liveStreamStateTypes = {
  liveStream: {} as LiveStreamState,
};

const liveStreamSlice = createSlice({
  name: "liveStream",
  initialState,
  reducers: {
    getLiveStream(state: any, action: PayloadAction<LiveStreamParams>) {},
    getLiveStreamSuccess(state: any, action: PayloadAction<LiveStreamState>) {
      state.liveStream = action.payload;
    },
  },
});

// Actions
export const { getLiveStream, getLiveStreamSuccess } = liveStreamSlice.actions;

// Reducer
export default liveStreamSlice.reducer;
