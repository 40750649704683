import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { API_URL_BASE } from '../constants';

declare module 'axios' {
  export interface AxiosRequestConfig {
    noNeedToken?: boolean,
    isFormData?: boolean,
    token?: string,
    url?: string
  }
};

const config = {
  baseURL: API_URL_BASE,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000
}

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
  (config: any) => {
    config.data = config.data || [];

    config.params = config.params || {};

    if (!config.noNeedToken) {
      config.headers = {
        Authorization: `Bearer ${config.token}`
      };
    }

    if (config.isFormData) {
      config.headers = {
        "Content-Type": "multipart/form-data"
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove("token_webnomi");
      return error.response;
      const win: Window = window;
      // win.location = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);
// use other login 14
export default axiosClient;
