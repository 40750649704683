import { EventLiveParams } from "types/eventLive";
import request from "./axiosService";
const URL_EVENT_LIVE = "/events-live";

export function getEventLiveAPI(params: EventLiveParams) {
  return request({
    url: URL_EVENT_LIVE,
    method: "get",
    params,
  });
}
export function getDetailEventLiveAPI(_id: string) {
  return request({
    url: URL_EVENT_LIVE + "/" + _id,
    method: "get",
  });
}
