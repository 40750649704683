import * as cts from "../../../constants/ui/svg";
import i18n from "locales/i18n";
import { UserOfShopState } from "types/userOfShop";
import { useAppSelector } from "app/hooks";
interface Props {
  arrInfo: any;
}
const TabProfile: React.FC<Props> = ({ arrInfo }) => {
  const detailHost = useAppSelector((state) => state.follow.detailHost);
  return (
    <div className=" px-[15vw] pb-[40px]  bg-white max-500px:px-4">
      <div className="col-span-3 bg-white rounded-[8px]  flex flex-col pt-6 justify-center items-center text-center max-500px:text-left">
        <>
          <span className="text-[#101828] text-[20px] notosans_bold max-500px:w-full">
            {detailHost?.nickName}
            {i18n.t("ABOUT")}
          </span>
          <div className="mt-[12px] text-[#101828] notosans_normal text-[16px] max-500px:text-sm">
            {detailHost?.description}
            {/* はじめまして〜！
            <br />
            ドラゴンタイプの新人Vliver、はりゅう あめの と申します ʚ❤︎ɞ
            <br />
            覗いていただけて嬉しいです ありがとう！
            <br />
            <br />
            <br />
            - ̗̀ 🌧 PROFILE 🌧 ̖́-
            <br />
            <br />
            <br />
            名前 : 羽竜あめの(はりゅうあめの) ꒰ঌ🌧໒꒱
            <br />
            Ameno Haryu
            <br />
            すき : わたがし(雲みたいだから)、パピコ、お肉
            <br />
            すき : お空の観察
            <br />
            すき : 読書、マンガ、アニメ、ゲーム、お絵描き
            <br />
            <br />
            <br />
            とても まったりのんびり配信をしております
            <br />
            乗れそうな雲をみつけたらあめのに教えてね‎️️️⛅️
            <br />
            <br />
            <br />
            ‎🤍⟡.·* next goal… フォロワー様 200人⤴︎ */}
          </div>
          <span className="text-[16px] notosans_bold my-6 max-500px:hidden">
            {i18n.t("CHANNEL_INFO")}
          </span>

          {arrInfo.map((x: any, y: any) => {
            return (
              <div key={y} className=" flex items-center max-500px:hidden">
                <span className="mr-[12px] icon_inf_channel">{x.icon}</span>
                <span className="text-[16px] notosans_normal">{x.ctn}</span>
              </div>
            );
          })}
        </>
      </div>

      <div className="hidden max-500px:flex flex-col  bg-[#F9FAFB] rounded-[8px] mt-4 px-3 pb-3">
        {arrInfo.map((x: any, y: any) => {
          return (
            <div key={y} className="mt-[12px] flex items-center">
              <span className="mr-[12px] icon_inf_channel">{x.icon}</span>
              <span className="text-[16px] max-1200px:text-[14px] notosans_normal text_1_line">
                {x.ctn}
              </span>
            </div>
          );
        })}
      </div>
      {/* <div>
        <div className="flex flex-col p-[20px] bg-white rounded-[8px]">
          <span className="text-[16px] notosans_bold mb-[8px]">{i18n.t("CHANNEL_INFO")}</span>
          {arrInfo.map((x: any, y: any) => {
            return (
              <div key={y} className="mt-[12px] flex items-center">
                <span className="mr-[12px] icon_inf_channel">{x.icon}</span>
                <span className="text-[16px] notosans_normal">{x.ctn}</span>
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};
export default TabProfile;
