import { call, put, select, takeEvery, delay, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

import i18n from "../locales/i18n";

import { toast, errorToast } from "../helpers";

import { showLoading } from "../slices/commonSlice";
import { giftNameListApi } from "../apis";
import * as act from "../slices/giftNameSlice";

function* fetchGiftNameListSaga(action: any) {
  yield put(showLoading("get-chats"));
  try {
    const res: AxiosResponse<any> = yield call(giftNameListApi, action.payload?.token, action.payload);
    if (res.data) {
      yield put(act.fetchGiftNameListSuccess(res.data))
      yield put(showLoading(false));
    } else {
      errorToast(i18n.t("GET_ERROR"));
      yield put(showLoading(false));
    }
  } catch (error) {
    errorToast(i18n.t("GET_ERROR"));
    yield put(showLoading(""));
  }
}

export default function* userWatcher() {
  yield takeEvery(act.fetchGiftNameList.type, fetchGiftNameListSaga);
}