import i18n from "locales/i18n";
import * as cts from "../../../constants/ui/svg";
import vid from "../../../assets/images/HOST IMAGE/pic02 (4).jpg";
import vid1 from "../../../assets/images/HOST IMAGE/pic01 (1).jpg";
import vid2 from "../../../assets/images/HOST IMAGE/pic01 (2).jpg";
import vid3 from "../../../assets/images/HOST IMAGE/pic01 (3).jpg";
import vid4 from "../../../assets/images/HOST IMAGE/pic01 (4).jpg";
import vid5 from "../../../assets/images/HOST IMAGE/pic01 (5).jpg";
import vid6 from "../../../assets/images/HOST IMAGE/pic01 (6).jpg";
import vid7 from "../../../assets/images/HOST IMAGE/pic01 (7).jpg";
import vid8 from "../../../assets/images/HOST IMAGE/pic01 (8).jpg";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const RankVideo = () => {
  const [tabFirst, setTabFirst] = useState(1);
  const [tabSecond, setTabSecond] = useState(1);
  const navigate = useNavigate();
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const rankingArr = [
    {
      id: 0,
      ranking: <span>{cts.rank1}</span>,
      avatar: vid,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },
    {
      id: 1,
      ranking: <span>{cts.rank2}</span>,
      avatar: vid1,
      name: "ニックネーム",
      coint: 21543,
      time: "57:07",
    },
    {
      id: 2,
      ranking: <span>{cts.rank3}</span>,
      avatar: vid2,
      name: "ニックネーム",
      coint: 21543,
      time: "50:07",
    },
    {
      id: 3,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">4</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid3,
      name: "ニックネーム",
      coint: 21543,
      time: "30:07",
    },
    {
      id: 4,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">5</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid4,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },
    {
      id: 5,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">6</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid5,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },
    {
      id: 6,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">7</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid6,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },

    {
      id: 7,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">8</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid7,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },
    {
      id: 8,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">9</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid8,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },
    {
      id: 9,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">10</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },
    {
      id: 10,
      ranking: (
        <div className="relative flex items-center justify-center">
          <span className="absolute text-[#182230] text-[13px] notosans_bold">11</span>
          <span>{cts.bgRank}</span>
        </div>
      ),
      avatar: vid1,
      name: "ニックネーム",
      coint: 21543,
      time: "1:23:07",
    },
  ];
  return (
    <div
      onClick={() => {
        window.scrollTo(0, 0);
        navigate({
          pathname: "/rank",
        });
      }}
      className="flex cursor-pointer w-full pt-[56px] pb-[50px] rank_home max-500px:pt-[24px] justify-center max-500px:pb-[16px]"
    >
      <div className="flex w-full flex-col size_content_hd">
        <div className="flex justify-between pb-[20px] max-500px:pb-[12px]">
          <div className="relative flex items-center flex-1">
            <div className="h-[36px] w-[36px] rounded-[50%] max-500px:h-[28px] max-500px:w-[28px] bg-[#F7B27A]"></div>
            <span className="text-[#101828] max-500px:text-[16px] max-500px:left-[7px] text-[20px] notosans_bold absolute left-[16px]">
              {i18n.t("RANKING")}{" "}
              <span className="text-[#667085] text-[14px] notosans_normal ml-[12px]">
                (Coming Soon)
              </span>
            </span>
          </div>
          {/* <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/rank"
            className="flex items-center cursor-pointer"
          >
            <span className="text-[14px] text-[#101828] max-500px:text-[13px] max-500px:text-[#667085] notosans_medium mr-[5px] w-full">
              {i18n.t("SHOW_MORE")}
            </span>
            <span>{cts.right1Icon}</span>
          </Link> */}
        </div>
        {/* <div className="flex items-center justify-center notosans_thin pb-[100px] w-full">
          {i18n.t("NO_DATA")}
        </div> */}
        {width <= 500 && (
          <div className="grid grid-cols-1 gap-2">
            <div className="border border-[#EAECF0] rounded-[8px] flex items-center justify-between px-[16px] h-[81px]">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <span className="icon_size_20">{cts.deliverIcon1}</span>
                  <span className="text-[14px] text-[#101828] ml-[8px] notosans_bold">
                    {i18n.t("DELIVERY_TIME")}
                  </span>
                </div>
                <div className="flex items-center mt-[4px]">
                  <span className="icon_respon_detail">{cts.detailIcon} </span>
                  <span className="text-[14px] text-[#667085] ml-[8px] notosans_normal">
                    {i18n.t("NATIONWIDE")}
                  </span>
                </div>
              </div>
              <div className="flex items-center mb-[10px]">
                <span className="text-[#667085] text-[14px] notosans_normal ml-[12px]">
                  (Coming Soon)
                </span>
                {/* {rankingArr
                  ?.filter((item, idx) => idx <= 2)
                  ?.map((x: any, y: any) => {
                    return (
                      <div className="relative flex justify-center items-end">
                        <img
                          className="h-[45px] w-[45px] rounded-[50%] object-cover"
                          src={x.avatar}
                        />
                        <span className="absolute top-[35px]">{x.ranking}</span>
                      </div>
                    );
                  })} */}
              </div>
            </div>
            <div className="border border-[#EAECF0] rounded-[8px] flex items-center justify-between px-[16px] h-[81px]">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <span className="icon_size_20">{cts.coin2}</span>
                  <span className="text-[14px] text-[#101828] ml-[8px] notosans_bold">
                    {i18n.t("POINT")}
                  </span>
                </div>
                <div className="flex items-center mt-[4px]">
                  <span className="icon_respon_detail">{cts.detailIcon} </span>
                  <span className="text-[14px] text-[#667085] ml-[8px] notosans_normal">
                    {i18n.t("NATIONWIDE")}
                  </span>
                </div>
              </div>
              <div className="flex items-center mb-[10px]">
                <span className="text-[#667085] text-[14px] notosans_normal ml-[12px]">
                  (Coming Soon)
                </span>
                {/* {rankingArr
                  ?.filter((item, idx) => idx <= 2)
                  ?.map((x: any, y: any) => {
                    return (
                      <div className="relative flex justify-center items-end">
                        <img
                          className="h-[45px] w-[45px] rounded-[50%] object-cover"
                          src={x.avatar}
                        />
                        <span className="absolute top-[35px]">{x.ranking}</span>
                      </div>
                    );
                  })} */}
              </div>
            </div>
          </div>
        )}
        <div className="items-center grid grid-cols-2 gap-4 max-800px:grid-cols-1 max-500px:hidden">
          <div className="flex flex-col border border-[rgba(0,0,0,0.1)] rounded-[8px] bg-white shadow-[0px_1px_3px_0px_#1018281A]">
            <div className="flex items-center justify-between px-[14px] h-[50px] bg-[#EF6820] rounded-t-[8px]">
              <span className="flex items-center text-white text-[18px] notosans_bold">
                <span className="mr-[8px]">{cts.deliverIcon1}</span>
                {i18n.t("DELIVERY_TIME")}
              </span>
              <span className="flex items-center text-white text-[16px] notosans_normal">
                <span className="mr-[8px]"> {cts.detailIcon}</span>
                {i18n.t("NATIONWIDE")}
              </span>
            </div>
            <div className="h-[374px] w-full flex items-center justify-center">
              <span className="text-[20px] text-[#667085] notosans_normal">Coming Soon</span>
            </div>
            {/* <div className=" items-center grid grid-cols-3">
              <div
                onClick={() => setTabFirst(1)}
                className={`${
                  tabFirst === 1
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("DAYS")}
              </div>
              <div
                onClick={() => setTabFirst(2)}
                className={`${
                  tabFirst === 2
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("WEEK")}
              </div>
              <div
                onClick={() => setTabFirst(3)}
                className={`${
                  tabFirst === 3
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("MONTHLY")}
              </div>
            </div>
            <div className="pt-[12px] flex flex-col pb-[8px] h-[334px] pr-[4px]">
              <span className="text-[#667085] ml-[16px] notosans_normal mb-[12px]">
                {i18n.t("DAILY_RANKING_UPDATE")}
              </span>
              <div className="rank_scroll overflow-y-auto flex flex-col">
                {rankingArr?.map((x: any, y: any) => {
                  return (
                    <div
                      key={y}
                      className={`${
                        y % 2 !== 0 && "bg-[#FEFAF5]"
                      } py-[12px] flex items-center justify-between pl-[16px] pr-[12px]`}
                    >
                      <div className="flex items-center">
                        {x.ranking}
                        <img
                          className="h-[32px] w-[32px] rounded-[16px] object-cover ml-[16px] mr-[12px]"
                          src={x.avatar}
                        />
                        <span className="text-[#101828] text-[16px] notosans_normal">{x.name}</span>
                      </div>
                      <span className="mr-[5px] text-[#EF6820] text-[16px] notosans_normal">
                        {x.time}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>
          <div className="flex flex-col border border-[rgba(0,0,0,0.1)] rounded-[8px] bg-white shadow-[0px_1px_3px_0px_#1018281A]">
            <div className="flex items-center justify-between px-[14px] h-[50px] bg-[#EF6820] rounded-t-[8px]">
              <span className="flex items-center text-white text-[18px] notosans_bold">
                <span className="mr-[8px]">{cts.coin2}</span>
                {i18n.t("POINT")}
              </span>
              <span className="flex items-center text-white text-[16px] notosans_normal">
                <span className="mr-[8px]"> {cts.detailIcon}</span>
                {i18n.t("NATIONWIDE")}
              </span>
            </div>
            <div className="h-[374px] w-full flex items-center justify-center">
              <span className="text-[20px] text-[#667085] notosans_normal">Coming Soon</span>
            </div>
            {/* <div className=" items-center grid grid-cols-3">
              <div
                onClick={() => setTabSecond(1)}
                className={`${
                  tabSecond === 1
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("DAYS")}
              </div>
              <div
                onClick={() => setTabSecond(2)}
                className={`${
                  tabSecond === 2
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("WEEK")}
              </div>
              <div
                onClick={() => setTabSecond(3)}
                className={`${
                  tabSecond === 3
                    ? "border-[#EF6820] text-[#EF6820] border-b-[2px] notosans_bold"
                    : "border-[rgba(0,0,0,0.1)] text-[#98A2B3] border-b"
                } text-[16px] notosans_normal h-[40px] flex items-center justify-center cursor-pointer`}
              >
                {i18n.t("MONTHLY")}
              </div>
            </div>
            <div className="pt-[12px] flex flex-col pb-[8px] h-[334px] pr-[4px]">
              <span className="text-[#667085] ml-[16px] notosans_normal mb-[12px]">
                {i18n.t("DAILY_RANKING_UPDATE")}
              </span>
              <div className="rank_scroll overflow-y-auto flex flex-col">
                {rankingArr?.map((x: any, y: any) => {
                  return (
                    <div
                      key={y}
                      className={`${
                        y % 2 !== 0 && "bg-[#FEFAF5]"
                      } py-[12px] flex items-center justify-between pl-[16px] pr-[12px]`}
                    >
                      <div className="flex items-center">
                        {x.ranking}
                        <img
                          className="h-[32px] w-[32px] rounded-[16px] ml-[16px] mr-[12px] object-cover"
                          src={x.avatar}
                        />
                        <span className="text-[#101828] text-[16px] notosans_normal">{x.name}</span>
                      </div>
                      <div className="flex items-center">
                        <span className="mr-[5px] text-[#EF6820] text-[16px] notosans_normal">
                          {x.coint}
                        </span>
                        <span>{cts.coinMini}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RankVideo;
